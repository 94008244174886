import Image from "next/image";
import React from "react";

interface OwnerNamespaceDropdownOptionProps {
  name: string;
  globalName: string;
  avatar: string;
}
const OwnerNamespaceDropdownOption: React.FC<OwnerNamespaceDropdownOptionProps> = ({ name, globalName, avatar }) => {
  return (
    <div className="flex w-full items-start justify-start gap-3">
      <Image
        src={avatar}
        alt={name}
        width={40}
        height={40}
        className="h-[40px] w-[40px] min-w-[40px] rounded-[8px]"
        crossOrigin="anonymous"
      />
      <div className="flex w-full flex-col items-start justify-start">
        <p className="font-gilroy text-[16px] font-[600] leading-[130%] text-[#0F0518]">{name}</p>
        <p className="font-gilroy text-[13px] font-[400] leading-[130%] text-[#0F0518]/50">{globalName}</p>
      </div>
    </div>
  );
};

export default OwnerNamespaceDropdownOption;
