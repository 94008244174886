import React, { FC } from "react";

interface JSTerminalIconInterface {
  className?: string;
}

const JSTerminalIcon: FC<JSTerminalIconInterface> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className}
      fill="none"
    >
      <g clipPath="url(#clip0_7464_54724)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H16V16H0V0ZM11.596 12.601C10.9656 12.601 10.6092 12.2722 10.3353 11.825L9.29673 12.4283C9.67192 13.1696 10.4387 13.7352 11.6256 13.7352C12.8394 13.7352 13.7433 13.1049 13.7433 11.9543C13.7433 10.887 13.1302 10.4123 12.0444 9.94664L11.7249 9.80978C11.1766 9.57232 10.9391 9.4171 10.9391 9.03371C10.9391 8.7235 11.1763 8.48604 11.5507 8.48604C11.9176 8.48604 12.154 8.64082 12.3731 9.03371L13.3683 8.39473C12.9474 7.65428 12.3632 7.37149 11.5507 7.37149C10.4093 7.37149 9.67905 8.10114 9.67905 9.05962C9.67905 10.1001 10.2917 10.5923 11.2139 10.9852L11.5334 11.1223C12.1163 11.3772 12.4638 11.5324 12.4638 11.9707C12.4638 12.3364 12.1255 12.601 11.596 12.601ZM6.64322 12.593C6.20414 12.593 6.02151 12.2919 5.82075 11.9357L4.78046 12.5655C5.08182 13.2033 5.67439 13.7328 6.69762 13.7328C7.83009 13.7328 8.60594 13.1306 8.60594 11.8072V7.44446H7.32797V11.79C7.32797 12.4287 7.0631 12.593 6.64322 12.593Z"
          fill="#FBFBFC"
          strokeWidth={1}
        />
      </g>
      <defs>
        <clipPath id="clip0_7464_54724">
          <rect width="16" height="16" rx="2.66667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default JSTerminalIcon;
