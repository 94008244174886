import React from "react";
import { graphql, useFragment } from "react-relay";

import { DashboardLeftSidebar_data$key } from "@generated/DashboardLeftSidebar_data.graphql";
import { cn } from "@libs/helper";

import ReadBlogSection from "./ReadBlogSection";
import ViewerCard from "./ViewerCard";
import ViewerNamespaces from "./ViewerNamespaces";
import styles from "./index.module.css";
import { DashboardLeftSidebarContext_data$key } from "@generated/DashboardLeftSidebarContext_data.graphql";
import RegularButton from "@components/shared/Buttons/RegularButton";
import PlusIcon from "@assets/PlusIcon";
import SectionSeparator from "@components/SectionSeparator";
import CreateAppButton from "@components/shared/Buttons/CreateAppButton";
import PublishPackageButton from "@components/shared/Buttons/PublishPackageButton";
import Link from "next/link";
import { DashboardLeftSidebarAppCard_data$key } from "@generated/DashboardLeftSidebarAppCard_data.graphql";
import { DashboardLeftSidebarPackageCard_data$key } from "@generated/DashboardLeftSidebarPackageCard_data.graphql";

const DashboardLeftSidebar_dataFragment = graphql`
  fragment DashboardLeftSidebar_data on Query {
    viewer {
      ...ViewerCard_data
    }
    ...ReadBlogSection_data
  }
`;

const DashboardLeftSidebarPackageCard_dataFragment = graphql`
  fragment DashboardLeftSidebarPackageCard_data on Package {
    id
    owner {
      globalName
    }
    icon
    name
    packageName
  }
`;

const PackageCard = ({ fragmentRef }: { fragmentRef: DashboardLeftSidebarPackageCard_data$key }) => {
  const data = useFragment(DashboardLeftSidebarPackageCard_dataFragment, fragmentRef);
  if (!data.packageName) return null;
  return (
    <Link href={`/${data.owner.globalName}/${data.packageName}`}>
      <div className="bg-light-gradient p-new-1 inline-flex max-w-full cursor-pointer items-center rounded-lg border border-[#0f051833] p-1">
        <img
          src={data.icon}
          crossOrigin="anonymous"
          loading="lazy"
          width="16"
          height="16"
          decoding="async"
          className=" m-1"
        ></img>
        <span className="ml-1 truncate whitespace-nowrap pr-4 text-sm font-medium">{data.name}</span>
      </div>
    </Link>
  );
};

const DashboardLeftSidebarAppCard_dataFragment = graphql`
  fragment DashboardLeftSidebarAppCard_data on DeployApp {
    id
    owner {
      globalName
    }
    favicon
    name
  }
`;

const DeployAppCard = ({ fragmentRef }: { fragmentRef: DashboardLeftSidebarAppCard_data$key }) => {
  const data = useFragment(DashboardLeftSidebarAppCard_dataFragment, fragmentRef);
  return (
    <Link href={`/apps/${data.owner.globalName}/${data.name}`}>
      <div className=" bg-light-gradient  p-new-1 inline-flex max-w-full cursor-pointer items-center rounded-lg border border-[#0f051833] p-1">
        <img src={data.favicon} loading="lazy" width="16" height="16" decoding="async" className="m-1"></img>
        <span className="ml-1 truncate whitespace-nowrap pr-4 text-sm font-medium ">{data.name}</span>
      </div>
    </Link>
  );
};

const DashboardLeftSidebarContext_dataFragment = graphql`
  fragment DashboardLeftSidebarContext_data on GlobalObject {
    ...ViewerCardContext_data
    __typename
    ... on User {
      username
      popularApps: apps(first: 5, sortBy: MOST_ACTIVE) {
        totalCount
        edges {
          node {
            ...DashboardLeftSidebarAppCard_data
          }
        }
      }
      popularPackages: packages(first: 5) {
        totalCount
        edges {
          node {
            ...DashboardLeftSidebarPackageCard_data
          }
        }
      }
    }
    ... on Namespace {
      globalName
      popularApps: apps(first: 5, sortBy: MOST_ACTIVE) {
        totalCount
        edges {
          node {
            ...DashboardLeftSidebarAppCard_data
          }
        }
      }
      popularPackages: packages(first: 5) {
        totalCount
        edges {
          node {
            ...DashboardLeftSidebarPackageCard_data
          }
        }
      }
    }
  }
`;

interface DashboardLeftSidebarProps {
  fragmentRef: DashboardLeftSidebar_data$key;
  contextFragmentRef: DashboardLeftSidebarContext_data$key;
  className?: string;
}

const DashboardLeftSidebar: React.FC<DashboardLeftSidebarProps> = ({ fragmentRef, className, contextFragmentRef }) => {
  const data = useFragment(DashboardLeftSidebar_dataFragment, fragmentRef);
  const context = useFragment(DashboardLeftSidebarContext_dataFragment, contextFragmentRef);

  const contextLink =
    context.__typename == "User"
      ? `/${context.username}`
      : context.__typename == "Namespace"
        ? `/${context.globalName}`
        : undefined;
  return (
    <aside className={cn(styles.wrapper, className)} data-cy="dashboard-left-sidebar">
      <div className={styles.dashboardLeftSidebarInner}>
        <div className={styles.dashboardLeftSidebarInnerTop}>
          <ViewerCard fragmentRef={data.viewer!} contextFragmentRef={context} />
          <RegularButton
            variant="secondary"
            text={context.__typename == "User" ? "View profile" : "View namespace"}
            link={contextLink}
          />
          <SectionSeparator />
          <div className="mb-4">
            <div className="mb-4 flex w-full items-center justify-between">
              <h4 className="font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">
                {context.__typename == "User" ? "Your apps" : "Apps"}
              </h4>
              <Link data-cy="deploy-app-btn" href={`/apps/create`}>
                <p className="flex flex-nowrap items-center justify-center gap-2 font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">
                  <PlusIcon className="w-2 min-w-2" /> Deploy
                </p>
              </Link>
            </div>
            <div className="flex flex-col gap-1">
              {(context.popularApps as any).totalCount == 0 ? (
                <>
                  <p>Ready to start deploying your apps in no time? Create an app from our existing templates</p>
                  <span className="display-inline">
                    <CreateAppButton />
                  </span>
                </>
              ) : (
                context.popularApps?.edges.map((edge) => {
                  if (!edge?.node) return;
                  return <DeployAppCard fragmentRef={edge.node} />;
                })
              )}
              {context.popularPackages?.totalCount && context.popularPackages?.totalCount > 5 && (
                <Link
                  className="mt-1 text-sm font-[600] text-[#605DE9] hover:underline"
                  href={`/${context.__typename == "User" ? context.username : context.globalName}?tab=apps`}
                >
                  See all
                </Link>
              )}
            </div>
          </div>
          <div>
            <div className="mb-4 flex w-full items-center justify-between">
              <h4 className="font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">
                {context.__typename == "User" ? "Your packages" : "Packages"}
              </h4>
              <Link
                data-cy="publish-package-btn"
                href="https://docs.wasmer.io/registry/cli#publish-a-pacakge-to-wasmer"
              >
                <p className="flex flex-nowrap items-center justify-center gap-2 font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">
                  <PlusIcon className="w-2 min-w-2" /> Publish
                </p>
              </Link>
            </div>
            <div className="flex flex-col gap-1">
              {(context.popularPackages as any).totalCount == 0 ? (
                <>
                  <p>Publish a package and share it with the community</p>
                  <span className="display-inline">
                    <PublishPackageButton />
                  </span>
                </>
              ) : (
                <>
                  {context.popularPackages?.edges.map((edge) => {
                    if (!edge?.node) return;
                    return <PackageCard fragmentRef={edge.node} />;
                  })}
                  {context.popularPackages?.totalCount && context.popularPackages?.totalCount > 5 && (
                    <Link
                      className="mt-1 text-sm font-[600] text-[#605DE9] hover:underline"
                      href={`/${context.__typename == "User" ? context.username : context.globalName}?tab=packages`}
                    >
                      See all
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <ReadBlogSection fragmentRef={data} />
      </div>
    </aside>
  );
};

export default DashboardLeftSidebar;
