import React, { FC } from "react";

interface CLITerminalIconInterface {
  className?: string;
}

const CLITerminalIcon: FC<CLITerminalIconInterface> = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_7464_54720)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H16V16H0V0ZM4.2437 4.70601C4.45671 4.493 4.80208 4.493 5.01509 4.70601L7.55044 7.24136C7.76346 7.45438 7.76346 7.79974 7.55044 8.01275L5.01509 10.5481C4.80208 10.7611 4.45671 10.7611 4.2437 10.5481C4.03069 10.3351 4.03069 9.98973 4.2437 9.77672L6.39336 7.62706L4.2437 5.4774C4.03069 5.26439 4.03069 4.91902 4.2437 4.70601ZM8.85254 9.39684C8.55129 9.39684 8.30709 9.64104 8.30709 9.94229C8.30709 10.2435 8.55129 10.4877 8.85254 10.4877H11.5527C11.854 10.4877 12.0982 10.2435 12.0982 9.94229C12.0982 9.64104 11.854 9.39684 11.5527 9.39684H8.85254Z"
          fill="#FA9E33"
        />
      </g>
      <defs>
        <clipPath id="clip0_7464_54720">
          <rect width="16" height="16" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CLITerminalIcon;
