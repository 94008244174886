"use client";
import React, { useCallback } from "react";
import { graphql, useFragment, usePaginationFragment } from "react-relay";

import ArrowLeft from "@components/PageCreateApp/ArrowLeft";
import UserProfileCard from "@components/UserProfileCard";
import { ViewerCard_data$key } from "@generated/ViewerCard_data.graphql";
import OwnerDropdown, { NamespaceOwner, OwnerType, UserOwner } from "./OwnerDropdown";

import SidebarItemCard from "../SidebarItemCard";
import styles from "./index.module.css";

import { OwnerSection_data$key } from "@generated/OwnerSection_data.graphql";
import { ViewerCardOwnerSection_namespacesPaginationQuery } from "@generated/ViewerCardOwnerSection_namespacesPaginationQuery.graphql";
import { useRouter } from "next/navigation";
import { ViewerCardContext_data$key } from "@generated/ViewerCardContext_data.graphql";
import NamespaceCard from "@components/NamespaceCard";
import { ViewerCardOwnerSection_namespacesData$key } from "@generated/ViewerCardOwnerSection_namespacesData.graphql";

const OwnerSection_namespacesDataFragment = graphql`
  fragment ViewerCardOwnerSection_namespacesData on User
  @argumentDefinitions(count: { type: Int, defaultValue: 10 }, cursor: { type: "String", defaultValue: null })
  @refetchable(queryName: "ViewerCardOwnerSection_namespacesPaginationQuery") {
    namespaces(first: $count, after: $cursor) @connection(key: "OwnerSection_namespaces") {
      edges {
        node {
          id
          avatar
          displayName
          globalName
          description
        }
      }
    }
  }
`;

const ViewerCard_dataFragment = graphql`
  fragment ViewerCard_data on User {
    id
    username
    avatar
    firstName
    lastName
    ...ViewerCardOwnerSection_namespacesData
    ...UserProfileCard_data
  }
`;

const ViewerCardContext_dataFragment = graphql`
  fragment ViewerCardContext_data on GlobalObject {
    __typename
    ...UserProfileCard_data
    ...NamespaceCard_data
  }
`;

interface ViewerCardProps {
  fragmentRef: ViewerCard_data$key;
  contextFragmentRef: ViewerCardContext_data$key;
  className?: string;
}

const ViewerCard: React.FC<ViewerCardProps> = ({ fragmentRef, contextFragmentRef, className }) => {
  const viewer = useFragment(ViewerCard_dataFragment, fragmentRef);
  const context = useFragment(ViewerCardContext_dataFragment, contextFragmentRef);
  const router = useRouter();
  const {
      data: namespacesData,
      loadNext,
      hasNext,
    } = usePaginationFragment<
      ViewerCardOwnerSection_namespacesPaginationQuery,
      ViewerCardOwnerSection_namespacesData$key
    >(OwnerSection_namespacesDataFragment, viewer),
    namespaceList: NamespaceOwner[] = namespacesData?.namespaces?.edges?.map((elem) => {
      return {
        type: OwnerType.namespace,
        id: elem?.node?.id!,
        avatar: elem?.node?.avatar!,
        name: elem?.node?.displayName!,
        globalName: elem?.node?.globalName!,
        description: elem?.node?.description!,
      };
    })!,
    onLoadingVisible = useCallback(() => {
      loadNext(5);
    }, [loadNext]),
    hasMore = hasNext,
    ownerViewer: UserOwner = {
      type: OwnerType.user,
      id: viewer.id!,
      avatar: viewer.avatar!,
      name: `${viewer.firstName} ${viewer.lastName}`,
      username: viewer.username!,
    },
    ownerList = [ownerViewer, ...namespaceList],
    handleOwnerChange = useCallback((newId: string) => {
      let selected = ownerList.find((val) => val.id == newId)!;
      console.log("handleOwnerchange", selected);
      if (selected.type == "user") {
        router.push(`/`);
      } else {
        router.push(`/?context=${selected.globalName}`);
      }
    }, []);

  return (
    <>
      {/* <Link className={cn(styles.wrapper, className)} href={`/${username}/`}> */}
      {/* </Link> */}
      <OwnerDropdown
        handleOwnerChange={handleOwnerChange}
        ownerList={ownerList}
        hasMore={hasMore}
        onLoadingVisible={onLoadingVisible}
      >
        {context.__typename == "User" ? (
          <SidebarItemCard className={styles.viewerCard} data-cy="viewer-card">
            <UserProfileCard fragmentRef={context} />
            <ArrowLeft className={`${styles.arrowLeft}`} />
          </SidebarItemCard>
        ) : context.__typename == "Namespace" ? (
          <SidebarItemCard className={styles.viewerCard} data-cy="viewer-card">
            <NamespaceCard fragmentRef={context} />
            <ArrowLeft className={`${styles.arrowLeft}`} />
          </SidebarItemCard>
        ) : (
          <></>
        )}
      </OwnerDropdown>
    </>
  );
};

export default ViewerCard;
