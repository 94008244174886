import React from "react";

const DeployIndicator = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="50 35 16 16" fill="none">
      <g opacity="0.4" filter="url(#filter0_dddd_4840_77951)">
        <circle cx="57" cy="44" r="8" fill="url(#paint0_radial_4840_77951)" />
      </g>
      <g filter="url(#filter1_di_4840_77951)">
        <circle cx="57" cy="44" r="4" fill="url(#paint1_radial_4840_77951)" shapeRendering="crispEdges" />
      </g>
      <defs>
        <filter
          id="filter0_dddd_4840_77951"
          x="0.386475"
          y="0.644711"
          width="113.227"
          height="113.227"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.600439" />
          <feGaussianBlur stdDeviation="1.1008" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.0274 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4840_77951" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.66014" />
          <feGaussianBlur stdDeviation="3.04358" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.035 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_4840_77951" result="effect2_dropShadow_4840_77951" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.99697" />
          <feGaussianBlur stdDeviation="7.32778" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.0426 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_4840_77951" result="effect3_dropShadow_4840_77951" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="13.2582" />
          <feGaussianBlur stdDeviation="24.3068" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.137255 0 0 0 0 0.0627451 0 0 0 0 0.266667 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_4840_77951" result="effect4_dropShadow_4840_77951" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_4840_77951" result="shape" />
        </filter>
        <filter
          id="filter1_di_4840_77951"
          x="47.5607"
          y="34.5607"
          width="18.8786"
          height="18.8786"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.71964" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.364706 0 0 0 0 0.803922 0 0 0 0 0.207843 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4840_77951" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4840_77951" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.43871" />
          <feGaussianBlur stdDeviation="0.719356" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_4840_77951" />
        </filter>
        <radialGradient
          id="paint0_radial_4840_77951"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.8667 33.9875) rotate(41.6559) scale(24.8076 24.6638)"
        >
          <stop stopColor="#FBFBFC" />
          <stop offset="1" stopColor="#FBFBFC" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4840_77951"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(60.3247 40.4734) rotate(118.157) scale(8.30502 7.98358)"
        >
          <stop stopColor="#02C389" />
          <stop offset="1" stopColor="#02C389" stopOpacity="0.8" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default DeployIndicator;
