/**
 * @generated SignedSource<<c036014b93475cdbcbb54cfe5eb429d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileCard_data$data = {
  readonly avatar: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly username: string;
  readonly " $fragmentType": "UserProfileCard_data";
};
export type UserProfileCard_data$key = {
  readonly " $data"?: UserProfileCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "54ec6d607d52712b5c56f84c0a75dec0";

export default node;
