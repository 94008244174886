import Image from "next/image";
import { useRouter } from "next/navigation";
import React from "react";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";

type Props = {
  icon: string;
  title: string;
  arrow: boolean;
  target?: string;
  id?: string;
  handleClick?: () => void;
  [restProps: string]: any;
};

const ProductButton: React.FC<Props> = ({ icon, title, arrow, target, handleClick, ...restProps }) => {
  const router = useRouter();
  const onButtonClick = () => {
    if (target) {
      router.push(`${target}`);
    }
  };

  return (
    <button
      {...restProps}
      // className={`bg-dark-black bg-hover-gradient text-light-white font-zeitung mx-3 inline-flex items-center rounded-full px-6 py-[11px] text-new-xl font-bold capitalize lg:mx-5 lg:text-new-2xl`}
      className={
        "bg-dark-black bg-hover-gradient text-light-white mx-3 inline-flex items-center rounded-full px-6 py-[11px] font-zeitung text-new-xl font-bold capitalize lg:mx-5 lg:text-new-2xl"
      }
      onClick={handleClick ?? onButtonClick}
    >
      <Image src={icon} alt="code" />
      <span className="ml-4">{title}</span>
      {arrow && (
        <span className="ml-4">
          <ArrowOutwardIcon color="#FBFBFC" width={12} height={12} />
        </span>
      )}
    </button>
  );
};

export default ProductButton;
