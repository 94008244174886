import "swiper/css";

import clsx from "clsx";
import { StaticImageData } from "next/image";
import React, { useState } from "react";

// @ts-ignore
import android from "@assets/icons/cardIcons/android.svg?url";
// @ts-ignore
import arc from "@assets/icons/cardIcons/arc.svg?url";
// @ts-ignore
import c from "@assets/icons/cardIcons/c.svg?url";
// @ts-ignore
import chrome from "@assets/icons/cardIcons/chrome.svg?url";
// @ts-ignore
import cli from "@assets/icons/cardIcons/cli.svg?url";
// @ts-ignore
import cpp from "@assets/icons/cardIcons/cpp.svg?url";
// @ts-ignore
import docker from "@assets/icons/cardIcons/docker.svg?url";
// @ts-ignore
import go from "@assets/icons/cardIcons/go.svg?url";
// @ts-ignore
import javascript from "@assets/icons/cardIcons/javascript.svg?url";
// @ts-ignore
import lua from "@assets/icons/cardIcons/lua.svg?url";
// @ts-ignore
import monkey from "@assets/icons/cardIcons/monkey.svg?url";
// @ts-ignore
import opera from "@assets/icons/cardIcons/opera.svg?url";
// @ts-ignore
import php from "@assets/icons/cardIcons/php.svg?url";
// @ts-ignore
import python from "@assets/icons/cardIcons/python.svg?url";
// @ts-ignore
import quickjs from "@assets/icons/cardIcons/quickjs.svg?url";
// @ts-ignore
import rust from "@assets/icons/cardIcons/rust.svg?url";
// @ts-ignore
import safari from "@assets/icons/cardIcons/safari.svg?url";
// @ts-ignore
import sqlite from "@assets/icons/cardIcons/sqlite.svg?url";
// @ts-ignore
import tantivy from "@assets/icons/cardIcons/tantivy.svg?url";
import bgTerminal from "@assets/images/bgTerminal.webp";
import leftTerminalGradient from "@assets/images/leftTerminalGradient.webp";
import rightTerminalGradient from "@assets/images/rightTerminalGradient.webp";
import PackageCard from "@components/PackageCardNew";

import css from "./TerminalCard.module.css";
import TerminalCodeBox from "./TerminalCodeBox";

function chunkIntoN<T>(arr: Array<T>, n: number): Array<Array<T>> {
  const size = Math.ceil(arr.length / n);
  return Array.from({ length: n }, (_v, i) => arr.slice(i * size, i * size + size));
}

type PackageType = {
  name: string;
  icon: StaticImageData;
  title: string;
  package: string;
  args?: string;
};

const packages: Array<PackageType> = [
  {
    name: "python",
    icon: python,
    title: "Python",
    package: "python/python",
  },
  {
    name: "bash",
    icon: cli,
    title: "Bash",
    package: "sharrattj/bash",
  },
  {
    name: "tantivy",
    icon: tantivy,
    title: "Tantivy",
    package: "tantivy/tantivy",
  },
  {
    name: "spidermonkey",
    icon: monkey,
    title: "SpiderMonkey",
    package: "mozilla/spidermonkey",
  },
  {
    name: "lua",
    icon: lua,
    title: "Lua",
    package: "syrusakbary/lua",
  },
  {
    name: "quickjs",
    icon: quickjs,
    title: "QuickJS",
    package: "saghul/quickjs",
  },
  {
    name: "sqlite",
    icon: sqlite,
    title: "SQLite",
    package: "sqlite/sqlite",
  },
  {
    name: "php",
    icon: php,
    title: "PHP",
    package: "php/php",
  },
  {
    name: "cowsay",
    icon: {
      src: "/logos/cowsay.png",
      width: 56,
      height: 56
    },
    title: "Cowsay",
    package: "cowsay",
    args: '"Hello world"',
  },
];

type SDKType = {
  name: string;
  icon: StaticImageData;
  install?: string;
  title?: string;
};

const sdks: Array<SDKType> = [
  {
    name: "cli",
    title: "CLI",
    install: "curl https://get.wasmer.io -sSfL | sh",
    icon: cli,
  },
  {
    name: "cliWindows",
    title: "CLI",
    install: "iwr https://win.wasmer.io -useb | iex",
    icon: cli
  },
  {
    name: "js",
    title: "JavaScript",
    install: "npm install @wasmer/sdk",
    icon: javascript,
  },
  {
    name: "chrome",
    icon: chrome,
  },
  {
    title: "Go",
    name: "go",
    icon: go,
  },
  {
    title: "Docker",
    name: "docker",
    icon: docker,
  },
  {
    name: "python",
    title: "Python",
    install: "pip install wasmer",
    icon: python,
  },
  {
    name: "opera",
    title: "Opera",
    icon: opera,
  },
  {
    name: "c",
    title: "C",
    icon: cpp,
  },
  {
    name: "rust",
    title: "Rust",
    icon: rust,
  },
  {
    name: "arc",
    title: "Arc",
    icon: arc,
  },
  {
    name: "safari",
    title: "Safari",
    icon: safari,
  },
  {
    name: "android",
    title: "Android",
    icon: android,
  },
  {
    title: "C",
    name: "c",
    icon: c,
  },
];
let pkgList = packages;
for (let index = 0; index < 10; index++) {
  pkgList = pkgList.concat(packages);
}

const packageLines = chunkIntoN<PackageType>(pkgList, 2);

const findPackage = (name: string): PackageType | undefined => {
  return packages.find((_package) => _package.name == name);
};

const findSDK = (name: string): SDKType | undefined => {
  return sdks.find((sdk) => sdk.name == name);
};

const TerminalCard = ({ os }: { os?: string }) => {
  const sdkCli = os ?
    os.match(/windows/i) ?
      "cliWindows"
      :
      "cli"
    : "cli";

  const [activePackage, setActivePackage] = useState<PackageType>(findPackage("cowsay")!);
  const [activeSDK, setActiveSDK] = useState<SDKType>(findSDK(sdkCli)!);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={clsx("relative", { ["h-[800px]"]: isExpanded })}>
      <div
        className={clsx("relative flex  flex-col md:overflow-hidden md:py-[88px]", {
          ["h-[800px]"]: isExpanded,
          ["h-[600px]"]: !isExpanded,
        })}
      >
        <div className={css.TerminalCardHeader}>Where do you want to run your containers?</div>
        <img
          src={leftTerminalGradient.src}
          width={385}
          height={385}
          alt="gradient"
          className="pointer-events-none absolute top-[30px] hidden mix-blend-color-dodge md:block"
        />
        <img
          src={rightTerminalGradient.src}
          width={316}
          height={420}
          alt="gradient"
          className="pointer-events-none absolute -top-[15%] right-0 hidden mix-blend-overlay md:block"
        />
        <div className=" hidden h-full flex-col items-center md:flex">
          <div className="flex  h-full flex-col items-center overflow-visible pt-[81px]">
            <div className="  flex h-full w-full flex-col items-center justify-center  ">
              {packageLines.map((packageLine, idx) => (
                <div
                  key={idx}
                  className={clsx(" inline-block  whitespace-nowrap   ", css.packagesSlides, {
                    [css.packagesSlidesLeft]: idx % 2 == 0,
                    [css.packagesSlidesRight]: idx % 2 != 0,
                  })}
                >
                  {packageLine?.map((_package: PackageType, key: number) => (
                    <div key={key} className={clsx("  inline-block whitespace-nowrap ")}>
                      <PackageCard
                        name={_package.name}
                        icon={_package?.icon}
                        title={_package?.title}
                        selected={activePackage && activePackage?.name == _package.name}
                        onClick={() => setActivePackage(_package)}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <img
          src={bgTerminal.src}
          width={688}
          height={518}
          alt="gradient"
          className={clsx({
            ["sm:max-h-auto pointer-events-none absolute -top-[12px] left-2/4 h-[calc(100%+12px)] min-w-[180%]   -translate-x-[50%]  opacity-50 xsm:min-w-[125%] xsm:opacity-100  sm:min-w-[initial]   "]:
              !isExpanded,
            ["sm:max-h-auto pointer-events-none absolute -top-[3%] left-2/4  h-[calc(100%+12px)] w-[80vw]  max-w-[90vw] -translate-x-[50%] opacity-50 xsm:min-w-[125%] xsm:opacity-100  sm:min-w-[initial]"]:
              isExpanded,
          })}
        />
      </div>
      <TerminalCodeBox
        packages={packages}
        sdks={sdks}
        os={os}
        selectedPackage={activePackage}
        selectedSDK={activeSDK}
        expanded={isExpanded}
        handlePackageChange={(name: string) => {
          let package_ = findPackage(name)!;
          setActivePackage(package_);
        }}
        handleSDKChange={(name: string) => {
          let sdk = findSDK(name)!;
          setActiveSDK(sdk);
        }}
        handleExpand={(expand) => {
          setIsExpanded(expand);
        }}
      />
    </div>
  );
};

export default TerminalCard;
