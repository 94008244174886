import React from "react";

interface ExpandIconInterface {
  className?: string;
  size?: number;
  svgFill?: string;
  fill?: string;
}
const ExpandIcon = ({ size = 32, fill = "#FBFBFC", svgFill = "none", className = "" }: ExpandIconInterface) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill={fill} fillOpacity="0.2" />
      <path d="M12 11L7 16L12 21" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 11L25 16L20 21" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ExpandIcon;
