/**
 * @generated SignedSource<<6fd1f0e94178c670499285f1771088e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewerCardContext_data$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"NamespaceCard_data" | "UserProfileCard_data">;
  readonly " $fragmentType": "ViewerCardContext_data";
};
export type ViewerCardContext_data$key = {
  readonly " $data"?: ViewerCardContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewerCardContext_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerCardContext_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfileCard_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NamespaceCard_data"
    }
  ],
  "type": "GlobalObject",
  "abstractKey": "__isGlobalObject"
};

(node as any).hash = "689df792a1c49af2d46fb482d0286464";

export default node;
