"use client"
import clsx from "clsx";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import React, { FC, useEffect, useRef, useState } from "react";

import CopyDocument from "@assets/CopyDocument";
import CLITerminalIcon from "@assets/icons/CLITerminalIcon";
import ExpandIcon from "@assets/icons/ExpandIcon";
import JSTerminalIcon from "@assets/icons/JSTerminalIcon";
import LedIcon from "@assets/icons/LedIcon";
// import PythonColoredIcon from "@assets/icons/cardIcons/PythonColoredIcon";
import ReturnIconDefault from "@assets/icons/ReturnIconDefault";

// import { type TerminalState } from "@components/Terminal";
import RegularButton from "@components/shared/Buttons/RegularButton";
//import getHighlighter from "@libs/getHighlighter";

import css from "./TerminalCodeBox.module.css";
import cssTryBtn from "./TerminalTryBtn.module.css";
//import Terminal from "@components/Terminal";
import { StaticImageData } from "next/image";
import dynamic from "next/dynamic";

type sectionType = "INSTALL" | "RUN" | undefined;

type TerminalState = any;

const Terminal = dynamic(() => import("@components/Terminal"), {
  loading: () => <p>Loading...</p>,
  ssr: false,
});

type PackageType = {
  name: string;
  icon: StaticImageData;
  title: string;
  package: string;
  args?: string;
};

type SDKType = {
  name: string;
  title?: string;
  install?: string;
  icon: StaticImageData;
};

function getJSCode(selectedPackage: PackageType) {
  let serializedArgs = selectedPackage.args
    ? `{
  args: ${JSON.stringify(selectedPackage.args)}
  }`
    : "";
  return `import { Wasmer } from "@wasmer/sdk";

let ${selectedPackage.name} = Wasmer.fromRegistry("${selectedPackage.package}");
let instance = await ${selectedPackage.name}.entrypoint.run(${serializedArgs});
let output = await instance.wait();`;
}

// function getPythonCode(selectedPackage: PackageType) {
//   let serializedArgs = selectedPackage.args
//     ? `
//   args=${JSON.stringify(selectedPackage.args)}
// `
//     : "";
//   return `from wasmer import Wasmer

// ${selectedPackage.name} = Wasmer.fromRegistry("${selectedPackage.package}")
// instance = await python.entrypoint.run(${serializedArgs})
// output = await instance.wait()`;
// }

const HighlightedCode = ({ children, lang }: { children: string; lang: string }) => {
  let [code, setCode] = React.useState<string | null>(null);

  React.useEffect(() => {
    import("@libs/getHighlighter").then((module) => {
      module.default().then((highlighter) => {
        setCode(highlighter.codeToHtml(children, { lang, theme: "github-dark" }));
      });
    })
  }, [children]);

  if (code) {
    return <div dangerouslySetInnerHTML={{ __html: code }} className="text-new-sm font-medium"></div>;
  } else {
    return <pre className="text-new-sm font-medium">{children}</pre>;
  }
};
const titleName = (name: string) => {
  switch (name) {
    case "python":
      return "example.py";
    case "js":
      return "example.js";
    case "cli":
    case "cliWindows":
    default:
      return "Run in CLI";
  }
};

interface TerminalCodeBoxInterface {
  selectedPackage: PackageType;
  selectedSDK: SDKType;
  expanded: boolean;
  os?: string;
  packages: Array<PackageType>;
  sdks: Array<SDKType>;

  handlePackageChange: (package_name: string) => void;
  handleSDKChange: (sdk_name: string) => void;
  handleExpand: (expanded: boolean) => void;
}

const TerminalCodeBox: FC<TerminalCodeBoxInterface> = ({
  selectedPackage,
  selectedSDK,
  expanded = false,
  os,
  // sdks,
  // packages,
  // handlePackageChange,
  handleSDKChange,
  handleExpand = () => {
    return;
  },
}) => {
  const [langInterface, setLangInterface] = useState<"CLI" | "JAVASCRIPT" | "PYTHON">("CLI");

  const [copySection, setCopySection] = useState<sectionType>();
  const [_, copy, isCopied] = useCopyToClipboard();
  const [CLIState, setCLIState] = useState<TerminalState>("idle");
  const [isFirstRun, setIsFirstRun] = useState(true);
  const terminalRef = useRef<any>(null);

  const handleCopy = (textToCopy: string, section: sectionType) => {
    let _textToCopy = textToCopy;

    if (section == "RUN") {
      switch (selectedSDK.name) {
        case "js":
          _textToCopy = getJSCode(selectedPackage);
          break;
        // case "python":
        //   _textToCopy = getPythonCode(selectedPackage);
        //   break;
        case "cli":
        case "cliWindows":
          _textToCopy =
            `wasmer run ${selectedPackage.package}${selectedPackage.args ? ` ${selectedPackage.args}` : ""}` || "";
          break;

        default:
          break;
      }
    }
    copy(_textToCopy);
    setCopySection(section);
  };
  useEffect(() => {
    if (!isCopied) {
      setCopySection(undefined);
    }
  }, [isCopied]);

  return (
    <div className={clsx(css.wrapper, { [css.wrapperExpanded]: expanded })}>
      <div className={css.contentWrap}>
        <div className={css.content}>
          <div className={css.headerBar}>
            <div className={css.headerContent}>
              <div
                className={clsx(css.headerBtn, {
                  [css.default]: langInterface != "CLI",
                  [css.active]: langInterface == "CLI",
                })}
                onClick={() => {
                  setLangInterface("CLI");
                  handleSDKChange(os ? (os?.match(/windows/i) ? "cliWindows" : "cli") : "cli");
                }}
              >
                <CLITerminalIcon className={clsx(css.icon, { [css.activeIcon]: langInterface == "CLI" })} />
                <div className={css.label}>CLI</div>
              </div>
              <div
                className={clsx(css.headerBtn, {
                  [css.default]: langInterface != "JAVASCRIPT",
                  [css.active]: langInterface == "JAVASCRIPT",
                })}
                onClick={() => {
                  setLangInterface("JAVASCRIPT");
                  handleSDKChange("js");
                }}
              >
                <JSTerminalIcon className={clsx(css.icon, { [css.activeIcon]: langInterface == "JAVASCRIPT" })} />
                <div className={css.label}>Javascript</div>
              </div>
            </div>
          </div>
          <div className={css.codeSection}>
            <div className={css.codeSectHeader}>Install</div>
            <div className={css.command}>$ {selectedSDK.install!}</div>
            <div className={css.copyIconWrap}>
              {isCopied && copySection == "INSTALL" && <span className={css.copiedLabel}>Copied</span>}
              <CopyDocument className={css.copyIcon} onClick={() => handleCopy(selectedSDK.install!, "INSTALL")} />{" "}
            </div>
          </div>
          <div
            className={clsx(css.codeSection, css.codeSectionRun, {
              [css.codeSectionDefault]: !expanded,
              [css.codeSectionRunExpanded]: expanded,
            })}
          >
            <div className={css.codeRun}>
              <div className={css.codeSectHeader}>{titleName(selectedSDK.name)}</div>
              <div className={css.command}>
                {(selectedSDK.name === "cli" || selectedSDK.name === "cliWindows") && (
                  // <div style={{ background: 'red', display: 'block', height: '100%', width: '100%' }} />
                  <Terminal
                    ref={terminalRef}
                    command={`wasmer run ${selectedPackage.package}${selectedPackage.args ? ` ${selectedPackage.args}` : ""}`}
                    onStateChange={(state) => {
                      setCLIState(state);
                      setIsFirstRun(isFirstRun && state == "idle");
                    }}
                  />
                )}
                {selectedSDK.name == "js" && <HighlightedCode lang="js">{getJSCode(selectedPackage)}</HighlightedCode>}

              </div>
              <div className={css.copyIconWrap}>
                {isCopied && copySection == "RUN" && <span className={css.copiedLabel}>Copied</span>}
                <CopyDocument className={css.copyIcon} onClick={() => handleCopy("", "RUN")} />{" "}
              </div>
            </div>
            {(selectedSDK.name == "cli" || selectedSDK.name === "cliWindows") && isFirstRun && (
              <div className={css.tryBtnWrap}>
                <RegularButton
                  variant="primary"
                  theme={cssTryBtn}
                  text="Try in your browser"
                  iconEnd={<ReturnIconDefault size={20} className={cssTryBtn.playIcon} />}
                  onClick={() => {
                    terminalRef.current?.onData("\r");
                  }}
                />
              </div>
            )}
            {(selectedSDK.name === "cli" || selectedSDK.name === "cliWindows") && CLIState != "idle" && (
              <div className={css.stateIndicator}>
                {(CLIState == "waiting" || CLIState == "listening") && (
                  <LedIcon variant="green" size={16} className="" />
                )}
                {CLIState == "running" && <LedIcon variant="green" size={16} className="animate-pulse-fast " />}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(css.expandIconWrap, { [css.expandIconWrapExpanded]: expanded })}
        onClick={() => {
          // setExpanded(!expanded);
          handleExpand(!expanded);
          terminalRef.current?.resize();
        }}
      >
        <ExpandIcon />
      </div>
    </div>
  );
};

export default TerminalCodeBox;