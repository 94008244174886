/**
 * @generated SignedSource<<fd385e56623a8d1829a0ec06f790b96f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewerCard_data$data = {
  readonly avatar: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileCard_data" | "ViewerCardOwnerSection_namespacesData">;
  readonly " $fragmentType": "ViewerCard_data";
};
export type ViewerCard_data$key = {
  readonly " $data"?: ViewerCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewerCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewerCardOwnerSection_namespacesData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfileCard_data"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3c1319770a4153cb818400ee5c585f8e";

export default node;
