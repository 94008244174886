import Link from "next/link";
import React, { FC } from "react";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
import { cn } from "@libs/helper";

type Props = {
  title: string;
  description: string;
  href: string;
  theme: "light" | "dark";
  className?: string;
};

const MidCTA: FC<Props> = ({ title, description, href, theme, className }) => {
  return (
    <div
      className={cn(
        "block items-center justify-between rounded-xl p-6 sm:flex lg:p-8",
        theme === "light" ? "bg-[#FBFBFC]" : "bg-dark-black",
        className
      )}
    >
      <div className="text-left">
        <h5
          className={cn(
            "mr-4 text-new-2xl font-medium lg:text-[28px]",
            theme === "light" ? "text-[#0F0518]" : "text-light-white"
          )}
        >
          {title}
        </h5>
        <p className={cn("mt-2.5 font-medium", theme === "light" ? "text-[#564F5C]" : "text-[#B4B1B8]")}>
          {description}
        </p>
      </div>
      <div className="mt-8 sm:mt-0">
        <Link href={href}>
          <button
            className={cn(
              "group ml-auto flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full sm:h-[54px] sm:w-[54px] sm:min-w-[54px]",
              theme === "light" ? "bg-dark-black" : "bg-[#FBFBFC]"
            )}
          >
            <span className="duration-200 group-hover:mb-1 group-hover:ml-1">
              <ArrowOutwardIcon color={theme === "light" ? "#FBFBFC" : "#0F0518"} width={12} height={12} />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MidCTA;
