import React from "react";
import { graphql, useFragment } from "react-relay";

import UserAvatarIcon from "@components/UserAvatarIcon";
import { UserProfileCard_data$key } from "@generated/UserProfileCard_data.graphql";
import { cn } from "@libs/helper";

import styles from "./index.module.css";

const UserProfileCard_dataFragment = graphql`
  fragment UserProfileCard_data on User {
    id
    username
    firstName
    lastName
    avatar
  }
`;

interface UserProfileCardProps {
  className?: string;
  fragmentRef: UserProfileCard_data$key;
  reverse?: boolean;
}

const UserProfileCard: React.FC<UserProfileCardProps> = ({ className, fragmentRef, reverse }) => {
  const data = useFragment(UserProfileCard_dataFragment, fragmentRef);
  const fullName = data?.firstName + " " + data?.lastName,
    username = data?.username!,
    avatar = data?.avatar!;
  return (
    <div
      className={cn("flex items-center justify-center gap-2 overflow-hidden", reverse && "flex-row-reverse", className)}
    >
      <UserAvatarIcon src={avatar} username={username!} className={styles.userAvatarIcon} />
      <div
        className={cn("flex w-full flex-col items-start justify-start truncate", reverse && "items-end justify-end")}
      >
        <p className="truncate font-gilroy text-[16px] font-[600] leading-[140%] text-[#0F0518]">{fullName}</p>
        <p className="truncate font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">{username}</p>
      </div>
    </div>
  );
};

export default UserProfileCard;
