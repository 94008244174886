import React from "react";
import { graphql, useFragment } from "react-relay";

import PageSection from "@components/PageCreateApp/PageSection";
import { Dashboard_data$key } from "@generated/Dashboard_data.graphql.js";

import DashboardActivity from "./DashboardActivity";
import DashboardLeftSidebar from "./DashboardLeftSidebar";
import styles from "./index.module.css";

const Dashboard_dataFragment = graphql`
  fragment Dashboard_data on Query @argumentDefinitions(context: { type: "String!" }) {
    viewer {
      ...DashboardActivityViewer_data
    }
    ...DashboardLeftSidebar_data
    context: getGlobalObject(slug: $context) {
      ...DashboardActivityContext_data
      ...DashboardLeftSidebarContext_data
    }
  }
`;

interface DashboardProps {
  fragmentRef: Dashboard_data$key;
}

const Dashboard: React.FC<DashboardProps> = ({ fragmentRef }) => {
  const data = useFragment(Dashboard_dataFragment, fragmentRef);

  return (
    <PageSection classNameForWrapper={styles.pageSectionWrapper} className={styles.wrapper} data-cy="dashboard">
      {/* Overlay for DashBoardActivity background gradient  */}
      <div className={styles.overlay} />
      {/* <MobileTopSection className={styles.mobileTopSection} fragmentRef={data} /> */}
      <DashboardLeftSidebar
        className={styles.dashboardLeftSidebar}
        fragmentRef={data}
        contextFragmentRef={data.context!}
      />
      {/* TODO: refractor DashboardActivity to use css modules */}
      <DashboardActivity viewerFragmentRef={data.viewer!} contextFragmentRef={data.context!} />
    </PageSection>
  );
};

export default Dashboard;
