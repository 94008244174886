import React from "react";
import { graphql, useFragment } from "react-relay";

import UserAvatarIcon from "@components/UserAvatarIcon";
import { cn } from "@libs/helper";

import styles from "./index.module.css";
import { NamespaceCard_data$key } from "@generated/NamespaceCard_data.graphql";

const NamespaceCard_dataFragment = graphql`
  fragment NamespaceCard_data on Namespace {
    id
    displayName
    globalName
    avatar
  }
`;

interface NamespaceCardProps {
  className?: string;
  fragmentRef: NamespaceCard_data$key;
  reverse?: boolean;
}

const NamespaceCard: React.FC<NamespaceCardProps> = ({ className, fragmentRef, reverse }) => {
  const data = useFragment(NamespaceCard_dataFragment, fragmentRef);
  const displayName = data?.displayName!,
    globalName = data?.globalName!,
    avatar = data?.avatar!;
  return (
    <div
      className={cn("flex items-center justify-center gap-2 overflow-hidden", reverse && "flex-row-reverse", className)}
    >
      <img
        src={avatar}
        alt={displayName}
        width={40}
        height={40}
        className="h-[40px] w-[40px] min-w-[40px] rounded-[8px]"
        crossOrigin="anonymous"
      />
      <div
        className={cn("flex w-full flex-col items-start justify-start truncate", reverse && "items-end justify-end")}
      >
        <p className="truncate font-gilroy text-[16px] font-[600] leading-[140%] text-[#0F0518]">{displayName}</p>
        <p className="truncate font-gilroy text-[13px] font-[400] leading-[140%] text-[#0F0518]/50">{globalName}</p>
      </div>
    </div>
  );
};

export default NamespaceCard;
