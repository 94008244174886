import React from "react";

// @ts-ignore
import c from "@assets/icons/iconLight/c.svg?url";
// @ts-ignore
import delvin from "@assets/icons/iconLight/delvin.svg?url";
// @ts-ignore
import go from "@assets/icons/iconLight/go.svg?url";
// @ts-ignore
import js from "@assets/icons/iconLight/js.svg?url";
// @ts-ignore
import python from "@assets/icons/iconLight/python.svg?url";
// @ts-ignore
import r from "@assets/icons/iconLight/r.svg?url";
// @ts-ignore
import line from "@assets/icons/line.svg?url";
// @ts-ignore
import run from "@assets/icons/run.svg?url";
import bgGradientsm from "@assets/images/bgGradientsm.webp";
import bgRunBox from "@assets/images/bgRunBox.webp";
import leftRunGradient from "@assets/images/leftRunGradient.webp";
import rightRunGradient from "@assets/images/rightRunGradient.webp";
import vercel from "@assets/images/vercel.webp";
import MidCTA from "@components/MidCTA";
import ProductButton from "@components/shared/Buttons/ProductButton";

const supportLang = [delvin, r, c, js, python, go];

type Props = {
  id?: string;
};

const RuntimeCode: React.FC<Props> = ({ id }) => {
  return (
    <div className="relative overflow-hidden pb-12 text-center xl:overflow-visible" id={id}>
      <img
        src={bgGradientsm.src}
        width={1152}
        height={394}
        alt="gradient"
        className="pointer-events-none absolute bottom-[27%] left-2/4 max-h-[500px] min-h-[500px] w-[145%] max-w-[1350px] -translate-x-[50%] mix-blend-darken xxsm:bottom-[24%] xsm:min-h-[440px] sm:bottom-[18%] md:bottom-0 md:max-h-[380px] md:min-h-[initial] md:w-[140%] md:max-w-[1100px] md:-translate-y-[50%] lg:bottom-[2%] xl:w-full"
      />
      <div id="runtime-home-section" className="mb-1">
        <img src={line.src} width={2} height={144} alt="line" className="mx-auto" />
      </div>
      <ProductButton icon={run} title="Runtime" arrow={false} />
      <h2 className="lg:leading-67px mx-2 mt-6 mb-4 text-[36px] font-medium leading-[56px] md:text-[40px] md:leading-[56px] lg:text-[48px]">
        Run the world
      </h2>
      <p className="text-dark-gray m-auto max-w-[750px] px-4 text-new-xl font-normal xsm:px-6 sm:px-5 lg:text-[22px]">
        Using a binary for each platform and chip is the past. Rise above with lightweight containerized apps that
        simply run everywhere.
      </p>
      <div className="relative pt-14 text-center sm:pt-[70px]">
        <div className="relative z-10 mx-auto flex flex-wrap items-center justify-center gap-4 px-16 xsm:gap-5 sm:px-0">
          {supportLang?.map((lang, key) => <img key={key} src={lang.src} width={42} alt="language" />)}
        </div>
        <h6 className="text-light-white relative z-10 my-4 mx-14 text-new-xl font-medium sm:mx-4 lg:text-[22px]">
          Supports almost every programming language
        </h6>
      </div>
      <div className="relative mx-4 max-w-full sm:mx-auto sm:max-w-[90%] lg:max-w-[826px]">
        <img
          src={leftRunGradient.src}
          width={241}
          height={285}
          alt="gradient"
          className="pointer-events-none absolute -left-[58%] bottom-[20%] mix-blend-overlay xxsm:-left-[50%] xsm:-left-[37%] sm:-left-[28%] sm:-bottom-[60%] xmd:-left-[22%] xmd:-bottom-[2%]"
        />
        <img
          src={rightRunGradient.src}
          width={285}
          height={334}
          alt="gradient"
          className="pointer-events-none absolute -right-[64%] bottom-[3%] mix-blend-color-dodge xxsm:-right-[56%] xsm:-right-[43%] sm:-right-[30%] sm:-bottom-[100%] xmd:-right-[23%] xmd:-bottom-[80%]"
        />
        <img
          src={bgRunBox.src}
          width={906}
          height={199}
          alt="gradient"
          className="pointer-events-none absolute -left-[10%] -bottom-[4.625rem] h-[150%] min-w-[120%] opacity-60 xsm:-bottom-[3.625rem] sm:-bottom-[2.625rem] md:-left-[5%] md:h-auto md:min-w-[110%] lg:-bottom-9 lg:opacity-100"
        />
        <MidCTA
          title="Truly universal, runs everywhere & fast as native"
          description="Learn more about the runtime"
          href="/products/runtime"
          theme="dark"
          className="z-1 relative"
        />
      </div>

      <p className="text-dark-gray invisible mx-auto mt-[50px] mb-6 max-w-[567px] px-[15px] text-new-xl font-medium lg:text-[22px]">
        “This programming tool makes it easier for apps to work anywhere”
      </p>
      <img src={vercel.src} width={136} height={32} alt="vercel" className="invisible mx-auto" />
    </div>
  );
};

export default RuntimeCode;
