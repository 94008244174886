import "swiper/css";

import React, { FC } from "react";
import { graphql, useFragment } from "react-relay";
import { Swiper, SwiperSlide } from "swiper/react";

// @ts-ignore
import line from "@assets/icons/line.svg?url";
// @ts-ignore
import publish from "@assets/icons/publish.svg?url";
import bgPublic from "@assets/images/bgPublic.webp";
import bgPublicBox from "@assets/images/bgPublicBox.webp";
// import bgPublicRadial from "@assets/images/bgPublicRadial.webp";
import leftPublicGradient from "@assets/images/leftPublicGradient.webp";
import rightPublicGradient from "@assets/images/rightPublicGradient.webp";
import MidCTA from "@components/MidCTA";
import PackageCard from "@components/PackageCard";
import ProductButton from "@components/shared/Buttons/ProductButton";
import { RegistryCode_recentPackages$key } from "@generated/RegistryCode_recentPackages.graphql";

import cssPkg from "./packagecard.module.css";

const RegistryCode_recentPackagesQueryFragment = graphql`
  fragment RegistryCode_recentPackages on Query {
    recentPackageVersions(first: 10, curated: true) {
      edges {
        node {
          #...LanguageBox_packageNode
          ...PackageCard_data
        }
      }
    }
  }
`;

type Props = {
  id?: string;
  fragmentRef: RegistryCode_recentPackages$key;
};

const RegistryCode: FC<Props> = ({ id, fragmentRef }) => {
  const data = useFragment(RegistryCode_recentPackagesQueryFragment, fragmentRef);
  const packagesList = data?.recentPackageVersions?.edges;

  return (
    <div className="relative overflow-hidden pb-12 text-center xl:overflow-visible" id={id}>
      <div id="registry-home-section" className="mb-1">
        <img src={line.src} width={2} height={144} alt="line" className="mx-auto" />
      </div>
      <ProductButton icon={publish} title="Registry" arrow={false} />
      <h2 className="lg:leading-67px mx-2 mt-6 mb-4 text-[36px] font-medium leading-[56px] md:text-[40px] md:leading-[56px] lg:text-[48px]">
        Unbound collaboration
      </h2>
      <p className="text-dark-gray m-auto max-w-[750px] px-4 text-new-xl font-normal sm:px-5 lg:text-[22px]">
        Packages are limited by their languages no more. Collaborate across stacks, leverage the ecosystem and
        contribute your own packages.
      </p>
      <div className="relative pt-16 text-center">
        <img
          src={bgPublic.src}
          width={1038}
          height={431}
          alt="gradient"
          className="pointer-events-none absolute top-[72%] left-2/4 h-full w-full min-w-[130%] -translate-y-[50%] -translate-x-[50%] opacity-50 xsm:opacity-70 sm:min-w-[auto] md:top-2/4 xmd:w-[826px]"
        />
        {/* <img
          src={bgPublicRadial.src}
          width={1152}
          height={519}
          alt="gradient"
          className="pointer-events-none absolute top-[70%] left-2/4 h-[519px] w-full -translate-y-[50%] -translate-x-[50%]"
        /> */}
        <div className="relative m-auto w-full min-w-full md:w-[762px] md:min-w-[762px]">
          <img
            src={leftPublicGradient.src}
            width={225}
            height={696}
            alt="gradient"
            className="pointer-events-none absolute -left-[180px] top-0 mix-blend-overlay sm:-left-[26%]"
          />
          <img
            src={rightPublicGradient.src}
            width={266}
            height={722}
            alt="gradient"
            className="pointer-events-none absolute -right-[180px] top-0 mix-blend-color-dodge sm:-right-[26%]"
          />
        </div>
        <div className="mx-4 block w-[calc(100%-32px)] pb-8 sm:mx-auto sm:w-full">
          <Swiper
            className="h-fit"
            spaceBetween={32}
            centeredSlides={true}
            initialSlide={1}
            slidesPerView={2.2}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 12,
              },
              640: {
                slidesPerView: 1.2,
                spaceBetween: 12,
              },
              768: {
                slidesPerView: 1.5,
                spaceBetween: 16,
              },
              900: {
                slidesPerView: 2.1,
                spaceBetween: 16,
              },
              1100: {
                slidesPerView: 2.5,
                spaceBetween: 32,
              },
              1280: {
                slidesPerView: 2.8,
                spaceBetween: 32,
              },
              1600: {
                slidesPerView: 3.5,
                spaceBetween: 32,
              },
            }}
          >
            {packagesList?.map((_slide, index) => {
              return (
                <SwiperSlide key={index} className={cssPkg.sliderItem}>
                  {/* <LanguageBox
                    fragmentRef={
                      data?.recentPackageVersions?.edges?.[index]?.node!
                    }
                  /> */}
                  <PackageCard
                    fragmentRef={data?.recentPackageVersions?.edges?.[index]?.node!}
                    className={cssPkg.pkgCardClassName}
                    forceMobileLayout={true}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className="relative mx-4 max-w-full sm:mx-auto sm:max-w-[90%] lg:max-w-[826px]">
        <img
          src={bgPublicBox.src}
          width={906}
          height={199}
          alt="gradient"
          className="absolute -left-[10%] -bottom-[20%] h-[120%] min-w-[120%] opacity-70 xsm:opacity-100 sm:-left-[5%] sm:-bottom-8 sm:h-[200px] sm:min-w-[110%] md:-bottom-9 md:h-auto"
        />
        <MidCTA
          title="All languages, fully containerized & collaborative"
          description="Learn more about the registry"
          href="/products/registry"
          theme="dark"
          className="z-1 relative"
        />
      </div>
    </div>
  );
};

export default RegistryCode;
