import React, { ReactNode, TextareaHTMLAttributes } from "react";
import DefaultInputBox, { DefaultInputBoxInterface } from "../DefaultInputBox";
import css from "./InputBoxWithAdorment.module.css";
import Adorment, { AdormentVariants } from "@components/shared/Adorment";
import clsx from "clsx";

interface InputBoxWithAdormentInterface extends DefaultInputBoxInterface {
  leftAdorment?: { text: ReactNode; className?: string; variant: AdormentVariants };
  rightAdorment?: { text: ReactNode; className?: string; variant: AdormentVariants };
}
const InputBoxWithAdorment = ({
  leftAdorment,
  rightAdorment,
  ...props
}: InputBoxWithAdormentInterface &
  React.AllHTMLAttributes<HTMLInputElement> &
  TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const theme = {
    inputBox: clsx({ [css.adjustLeft]: leftAdorment, [css.adjustRight]: rightAdorment }),
  };
  return (
    <div className={css.inputBoxAdorment}>
      {leftAdorment && (
        <Adorment
          variant={leftAdorment.variant}
          text={leftAdorment.text}
          className={clsx(css.leftAdorment, leftAdorment.className)}
        />
      )}
      <DefaultInputBox {...props} theme={theme} />
      {rightAdorment && (
        <Adorment
          variant={rightAdorment.variant}
          text={rightAdorment.text}
          className={clsx(css.rightAdorment, rightAdorment.className)}
        />
      )}
    </div>
  );
};
export default InputBoxWithAdorment;
