/**
 * @generated SignedSource<<69b61f9ac940378155764c267cf93d4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardActivityContext_data$data = {
  readonly __typename: "Namespace";
  readonly apps: {
    readonly totalCount: number | null | undefined;
  };
  readonly displayName: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ActivityListForNamespace_data">;
  readonly " $fragmentType": "DashboardActivityContext_data";
} | {
  readonly __typename: "User";
  readonly apps: {
    readonly totalCount: number | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ActivityListForUser_data">;
  readonly " $fragmentType": "DashboardActivityContext_data";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "DashboardActivityContext_data";
};
export type DashboardActivityContext_data$key = {
  readonly " $data"?: DashboardActivityContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityContext_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "sortBy",
      "value": "MOST_ACTIVE"
    }
  ],
  "concreteType": "DeployAppConnection",
  "kind": "LinkedField",
  "name": "apps",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "apps(sortBy:\"MOST_ACTIVE\")"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardActivityContext_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityListForUser_data"
        },
        (v0/*: any*/)
      ],
      "type": "User",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityListForNamespace_data"
        },
        (v0/*: any*/)
      ],
      "type": "Namespace",
      "abstractKey": null
    }
  ],
  "type": "GlobalObject",
  "abstractKey": "__isGlobalObject"
};
})();

(node as any).hash = "9de4b314a0dcb8df30adf28001bf0cfd";

export default node;
