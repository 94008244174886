import { type ReactNode } from "react";
import css from "./index.module.css";

export type ShinyBoxProps = {
  className?: string;
  children: ReactNode;
  blur?: "big" | "medium" | "small";
  variant?: "mix" | "green" | "purple" | "yellow" | "none";
};
const BLUR_CSS = {
  big: "max-xxlg:blur-xl xxlg:blur-2xl",
  medium: "max-xxlg:blur-lg xxlg:blur-xl",
  small: "max-xxlg:blur-md xxlg:blur-lg",
};
const VARIANT_CSS = {
  mix: css.mix,
  green: css.green,
  purple: css.purple,
  yellow: css.mix,
  none: css.none,
};
export default ({ children, className, blur = "big", variant = "mix" }: ShinyBoxProps) => {
  return (
    <div className={`z-100 relative ${className}`}>
      {variant != "none" ? (
        <div
          className={`${css.background} pointer-events-none h-full w-full ${BLUR_CSS[blur]} ${VARIANT_CSS[variant]}`}
        />
      ) : null}
      {children}
    </div>
  );
};
