import React, { FC } from "react";
import { graphql, useFragment } from "react-relay";

// @ts-ignore
import Infinitescalability from "@assets/icons/Infinitescalability.svg?url";
// @ts-ignore
import Instantcoldstarts from "@assets/icons/Instantcoldstarts.svg?url";
// @ts-ignore
import edge from "@assets/icons/edgeion.svg?url";
// @ts-ignore
import line from "@assets/icons/line.svg?url";
import bgDeploy from "@assets/images/bgDeploy.webp";
// import bgDeployRadial from "@assets/images/bgDeployRadial.webp";
import bgDeployShadow from "@assets/images/bgDeployShadow.webp";
import TemplateCard from "@components/AppTemplate/TemplateCard";
import MidCTA from "@components/MidCTA";
import ProductButton from "@components/shared/Buttons/ProductButton";
import { EdgeCode_Query$key } from "@generated/EdgeCode_Query.graphql";
import { cn } from "@libs/helper";

type Props = {
  id?: string;
  fragmentRef: EdgeCode_Query$key;
};

const EdgeCode_Query_fragment = graphql`
  fragment EdgeCode_Query on Query {
    apps: getAppTemplates(categorySlug: "popular", first: 3) {
      edges {
        node {
          id
          ...TemplateCard_AppTemplate
        }
      }
    }
  }
`;

const EdgeCode: FC<Props> = ({ id, fragmentRef }) => {
  const templateData = useFragment(EdgeCode_Query_fragment, fragmentRef);
  const appClassNames = [
    "absolute left-0 top-0 min-w-[530px]",
    "absolute right-0 top-0 min-w-[530px]",
    "relative left-2/4 -top-[88px] -translate-x-[50%] min-w-[530px]",
  ];
  return (
    <div className="relative overflow-hidden pb-12 text-center xl:overflow-visible" id={id}>
      <img
        src={bgDeploy.src}
        width={1562}
        height={1044}
        alt="gradient"
        className="pointer-events-none absolute -bottom-[18%] left-2/4 h-auto max-h-[520px] w-[390%] max-w-[1200px] -translate-y-[50%] -translate-x-[50%] mix-blend-darken xxsm:max-h-[520px] xxsm:w-[310%] xsm:max-h-[520px] xsm:w-[300%] sm:bottom-[-30%] sm:max-h-[550px] sm:w-[140%] xl:w-full"
      />
      <div id="edge-home-section" className="mb-1">
        <img src={line.src} width={2} height={144} alt="EdgeCode" className="mx-auto" />
      </div>
      <ProductButton icon={edge} title="Edge" arrow={false} />
      <h2 className="lg:leading-67px mx-2 mt-6 mb-4 text-[36px] font-medium leading-[56px] md:text-[40px] md:leading-[56px] lg:text-[48px]">
        Above the clouds
      </h2>
      <p className="text-dark-gray m-auto max-w-[750px] px-4 text-new-xl font-normal sm:px-5 lg:text-[22px]">
        Get the scalability of serverless and the reusability of cloud. Deploy to the edge, save your users time and
        yourself money.{" "}
      </p>
      <div className="relative px-4 pt-36 text-center">
        <div className="relative mx-auto flex w-[670px] md:w-auto md:max-w-[90%] lg:max-w-[826px]">
          {templateData.apps?.edges.map((edge, i) => {
            if (!edge?.node) return;
            return (
              <TemplateCard
                key={edge.node.id}
                templateRef={edge.node}
                className={cn("absolute", appClassNames?.[i])}
                mode="large"
              />
            );
          })}
        </div>
        <div className="mx-auto my-[40px] mt-[20px] mb-[30px] table items-center justify-center sm:flex">
          <div className="flex items-center pr-[30px]">
            <img src={Infinitescalability.src} width={39} height={38} alt="infinite scalability" className="xs:mx-auto" />
            <h6 className="text-light-white relative z-20 my-2 ml-2 text-new-xl font-medium lg:text-[22px]">
              Infinite scalability
            </h6>
          </div>
          <div className="flex items-center">
            <img src={Instantcoldstarts.src} width={33} height={39} alt="insetant cold starts" className="xs:mx-auto xs:w-auto" />
            <h6 className="text-light-white relative z-20 my-2 ml-2 text-new-xl font-medium lg:text-[22px]">
              Instant cold starts
            </h6>
          </div>
        </div>
      </div>
      <div className="relative mx-4 max-w-full sm:mx-auto sm:max-w-[90%] lg:max-w-[826px]">
        {/* <img
          src={bgDeployRadial.src}
          width={1152}
          height={599}
          alt="gradient"
          className="pointer-events-none absolute -left-[18%] -bottom-[2%] mix-blend-overlay"
        /> */}
        <img
          src={bgDeployShadow.src}
          width={906}
          height={199}
          alt="gradient"
          className="pointer-events-none absolute -left-[10%] -bottom-16 h-[140%] min-w-[130%] opacity-60 sm:-left-[5%] sm:-bottom-9 sm:h-auto sm:min-w-[110%]"
        />
        <MidCTA
          title="Faster, affordable & indefinitely scalable"
          description="Learn more about Edge"
          href={"/products/edge"}
          theme="dark"
          className="z-1 relative"
        />
      </div>
      {/*
      <p className="text-dark-gray mx-auto mt-[50px] mb-6 max-w-[567px] px-[15px] text-new-xl font-medium lg:text-[22px]">
        {" "}
        “This programming tool makes it easier for apps to work anywhere”
      </p>
      <Image src={vercel} alt="vercel" className="mx-auto" />
      */}
    </div>
  );
};

export default EdgeCode;
