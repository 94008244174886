import Image from "next/image";
import React from "react";

// @ts-ignore
import Astro from "@assets/icons/frameworks/astro.svg?url";
// @ts-ignore
import Django from "@assets/icons/frameworks/django.svg?url";
// @ts-ignore
import Docusaurus from "@assets/icons/frameworks/docusaurus.svg?url";
// @ts-ignore
import Flask from "@assets/icons/frameworks/flask.svg?url";
// @ts-ignore
import Gatsby from "@assets/icons/frameworks/gatsby.svg?url";
// @ts-ignore
import Hugo from "@assets/icons/frameworks/hugo.svg?url";
// @ts-ignore
import Next from "@assets/icons/frameworks/next.svg?url";
// @ts-ignore
import Nuxt from "@assets/icons/frameworks/nuxt.svg?url";
// @ts-ignore
import Remix from "@assets/icons/frameworks/remix.svg?url";
// @ts-ignore
import Svelte from "@assets/icons/frameworks/svelte.svg?url";
// @ts-ignore
import Vue from "@assets/icons/frameworks/vue.svg?url";

interface FrameworkIconProps {
  framework:
    | "astro"
    | "django"
    | "docusaurus"
    | "flask"
    | "gatsby"
    | "hugo"
    | "next.js"
    | "nuxt"
    | "remix"
    | "svelte"
    | "vue"
    | string;
  className?: string;
}

const FrameworkIcon: React.FC<FrameworkIconProps> = ({ framework, className }) => {
  let indexedFramework = framework.toLocaleLowerCase();
  if (indexedFramework == "next.js" || indexedFramework == "nextjs") {
    indexedFramework = "next";
  }
  const frameworks: any = {
    astro: Astro,
    django: Django,
    docusaurus: Docusaurus,
    flask: Flask,
    gatsby: Gatsby,
    hugo: Hugo,
    next: Next,
    nuxt: Nuxt,
    remix: Remix,
    svelte: Svelte,
    vue: Vue,
  };
  let iconSrc = frameworks[indexedFramework];
  if (iconSrc) {
    return <Image className={className} alt={`${framework} icon`} src={iconSrc!} height={20} width={20} />;
  }
  return null;
};

export default FrameworkIcon;
