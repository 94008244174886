import React from "react";

import { cn } from "@libs/helper";

import styles from "./index.module.css";

interface SectionSeparatorProps {
  className?: string;
}

const SectionSeparator: React.FC<SectionSeparatorProps> = ({ className }) => {
  return <hr className={cn(styles.SectionSeparator, className)} />;
};

export default SectionSeparator;
