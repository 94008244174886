import React from "react";

type variants = "green";
type variantType = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
};
const variantStyle: { [key in variants]: variantType } = {
  green: {
    color1: "#00c300",
    color2: "#aeffae",
    color3: "#fff",
    color4: "#0dee1b",
    color5: "#000",
    color6: "#a6a6a6",
    color7: "#353535",
  },
};

interface LedIconInterface {
  variant: variants;
  size?: number;
  className?: string;
}

const LedIcon = ({ size = 22, variant = "green", className = "" }: LedIconInterface) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 6 6"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <defs>
        <radialGradient id="radialGradient7003" cx="47.5" cy="21.5" r="2.5" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={variantStyle[variant].color1} stopOpacity="0"></stop>
          <stop offset="0.82" stopColor={variantStyle[variant].color1} stopOpacity="0.498"></stop>
          <stop offset="1" stopColor={variantStyle[variant].color2}></stop>
        </radialGradient>
        <radialGradient id="radialGradient6993" cx="47.5" cy="21.5" r="2.5" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={variantStyle[variant].color3}></stop>
          <stop offset="1" stopColor={variantStyle[variant].color4} stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient id="radialGradient7039" cx="-87.5" cy="62.5" r="2.5" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor={variantStyle[variant].color5}></stop>
          <stop offset="0.516" stopColor={variantStyle[variant].color5}></stop>
          <stop offset="0.76" stopColor={variantStyle[variant].color6}></stop>
          <stop offset="0.9" stopColor={variantStyle[variant].color7}></stop>
          <stop offset="1" stopColor={variantStyle[variant].color5}></stop>
        </radialGradient>
      </defs>
      <g transform="translate(-437 -640.79)">
        <path
          fill="url(#radialGradient7039)"
          d="M-85 62.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
          transform="matrix(1.2 0 0 1.2 545 568.79)"
        ></path>
        <path
          fill={variantStyle[variant].color1}
          d="M50 21.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
          transform="translate(392.5 622.29)"
        ></path>
        <path
          fill="url(#radialGradient6993)"
          d="M50 21.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
          transform="translate(392.9 621.89)"
        ></path>
        <path
          fill="url(#radialGradient7003)"
          d="M50 21.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"
          transform="translate(392.5 622.29)"
        ></path>
      </g>
    </svg>
  );
};
export default LedIcon;
