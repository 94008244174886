/**
 * @generated SignedSource<<fbc5336f889bdb8086c53b99012dd797>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardLeftSidebarContext_data$data = {
  readonly __typename: string;
  readonly globalName?: string;
  readonly popularApps?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebarAppCard_data">;
      } | null | undefined;
    } | null | undefined>;
    readonly totalCount: number | null | undefined;
  };
  readonly popularPackages?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebarPackageCard_data">;
      } | null | undefined;
    } | null | undefined>;
    readonly totalCount: number | null | undefined;
  };
  readonly username?: string;
  readonly " $fragmentSpreads": FragmentRefs<"ViewerCardContext_data">;
  readonly " $fragmentType": "DashboardLeftSidebarContext_data";
};
export type DashboardLeftSidebarContext_data$key = {
  readonly " $data"?: DashboardLeftSidebarContext_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebarContext_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 5
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v2 = {
  "alias": "popularApps",
  "args": [
    (v0/*: any*/),
    {
      "kind": "Literal",
      "name": "sortBy",
      "value": "MOST_ACTIVE"
    }
  ],
  "concreteType": "DeployAppConnection",
  "kind": "LinkedField",
  "name": "apps",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DeployAppEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeployApp",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DashboardLeftSidebarAppCard_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "apps(first:5,sortBy:\"MOST_ACTIVE\")"
},
v3 = {
  "alias": "popularPackages",
  "args": [
    (v0/*: any*/)
  ],
  "concreteType": "PackageConnection",
  "kind": "LinkedField",
  "name": "packages",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Package",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DashboardLeftSidebarPackageCard_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "packages(first:5)"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardLeftSidebarContext_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewerCardContext_data"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "User",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "globalName",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "Namespace",
      "abstractKey": null
    }
  ],
  "type": "GlobalObject",
  "abstractKey": "__isGlobalObject"
};
})();

(node as any).hash = "ae6a7cd80458508e098612962e185f9d";

export default node;
