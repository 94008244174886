"use client";

import Script from "next/script";

import { graphql, usePreloadedQuery } from "react-relay";

import Dashboard from "@components/Dashboard";
import LayoutWrapper from "@components/LayoutWrapper";
import PublicHomePage from "@components/PublicHomePage";
import withClientComponent from "@components/withClientComponent";
import PageHomeQueryNode, { PageHomeQuery } from "@generated/PageHomeQuery.graphql";

const PageHome = withClientComponent<typeof PageHomeQueryNode, PageHomeQuery, { os?: string }>((props) => {
  const data = usePreloadedQuery(
    graphql`
      query PageHomeQuery($context: String!) {
        viewer {
          ...LayoutWrapper_data
        }
        ...PublicHomePage_data @skipIfLoggedIn
        ...Dashboard_data @arguments(context: $context) @includeIfLoggedIn
      }
    `,
    props.queryRef
  );

  // return (
  //   <Suspense fallback="Loading (client side)...">
  //     <Header viewer={data.viewer!} />
  //   </Suspense>
  // );
  return (
    <LayoutWrapper fragmentRef={data.viewer || null}>
      {data.viewer ? (
        <Dashboard fragmentRef={data} />
      ) : (
        <>
          {/* We import this manually, specifically as a module, because our bundler seems to have a problem with it upon build. */}
          {/*<Script type="module" src="https://unpkg.com/@wasmer/sdk@0.6.0"></Script>*/}
          <PublicHomePage fragmentRef={data} className="mb-28" os={props.os} />
        </>
      )}
    </LayoutWrapper>
  );
});

export default PageHome;
