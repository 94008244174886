/**
 * @generated SignedSource<<a18b49809a0de985c57f84473b8aed9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublicHomePage_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EdgeCode_Query" | "RegistryCode_recentPackages">;
  readonly " $fragmentType": "PublicHomePage_data";
};
export type PublicHomePage_data$key = {
  readonly " $data"?: PublicHomePage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublicHomePage_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PublicHomePage_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RegistryCode_recentPackages"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EdgeCode_Query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8952e74f6b48fe3320b31428262107bf";

export default node;
