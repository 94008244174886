import React from "react";

interface CliSourceProps {
  className?: string;
}
const CliSource = ({ className }: CliSourceProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className={className}
    >
      <g clipPath="url(#a)">
        <path
          stroke="#0F0518"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.375"
          d="m6.365 7 3.486 3.486-3.486 3.486m5.807-.302h3.713"
        />
        <rect
          width="20.5"
          height="20.5"
          x=".75"
          y=".75"
          stroke="#0F0518"
          strokeOpacity=".2"
          strokeWidth="1.5"
          rx="4.125"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="22" height="22" fill="#fff" rx="4.125" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CliSource;
