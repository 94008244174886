/**
 * @generated SignedSource<<007777267f38b0ca0b2be66b073399a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegistryCode_recentPackages$data = {
  readonly recentPackageVersions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"PackageCard_data">;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly " $fragmentType": "RegistryCode_recentPackages";
};
export type RegistryCode_recentPackages$key = {
  readonly " $data"?: RegistryCode_recentPackages$data;
  readonly " $fragmentSpreads": FragmentRefs<"RegistryCode_recentPackages">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RegistryCode_recentPackages",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "curated",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "PackageVersionConnection",
      "kind": "LinkedField",
      "name": "recentPackageVersions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PackageVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PackageVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PackageCard_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "recentPackageVersions(curated:true,first:10)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "3002bb624c6761d7ce6923c4e084cd60";

export default node;
