import Image from "next/image";
import Link from "next/link";
import React from "react";
import { graphql, useFragment } from "react-relay";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
import IconButton from "@components/shared/Buttons/IconButton";
import { TemplateCard_AppTemplate$key } from "@generated/TemplateCard_AppTemplate.graphql";
import { cn } from "@libs/helper";

import FrameworkIcon from "../FrameworkIcon";
import css from "./TemplateCard.module.css";

const TemplateCard_dataFragment = graphql`
  fragment TemplateCard_AppTemplate on AppTemplate {
    name
    description
    demoUrl
    repoUrl
    framework
    defaultImage
    slug
  }
`;

interface TemplateCardInterface {
  templateRef: TemplateCard_AppTemplate$key;
  className?: string;
  mode?: "large" | "small";
  goToDeploy?: boolean;
}

const TemplateCard: React.FC<TemplateCardInterface> = ({ templateRef, className, mode = "small", goToDeploy }) => {
  const templateData = useFragment(TemplateCard_dataFragment, templateRef);
  const icon = templateData.framework ? <FrameworkIcon framework={templateData.framework} /> : null;
  const link = goToDeploy ? `/apps/create?template=${templateData.slug}` : `/templates/${templateData.slug}`;
  return (
    <div className={cn(css.wrapper, className, mode == "large" && css.large)}>
      <div className="h-full w-full">
        <Link href={link} className={css.coverImg} data-cy="app-template-card">
          <Image src={templateData.defaultImage!} fill style={{ objectFit: "cover" }} alt="cover" />
        </Link>
        <div className={css.overview}>
          {icon && (
            <IconButton
              icon={icon}
              className={css.framework}
              link={`/templates?framework=${templateData.framework.toLowerCase()}`}
            />
          )}
          <Link href={link} className={css.overviewCont}>
            <span className={css.title}>{templateData.name}</span>
            {mode == "large" ? null : <div className={css.overviewText}>{templateData.description}</div>}
          </Link>
          <Link href={templateData.demoUrl} target="_blank" className={css.demo}>
            {mode == "large" ? (
              <button className="bg-dark-black group flex h-[48px] w-[48px] min-w-[48px] items-center justify-center rounded-full sm:h-[54px] sm:w-[54px] sm:min-w-[54px]">
                <span className="duration-200 group-hover:mb-1 group-hover:ml-1">
                  <ArrowOutwardIcon color="#FBFBFC" width={12} height={12} />
                </span>
              </button>
            ) : (
              <button className="bg-dark-black group flex h-[28px] w-[28px] min-w-[28px] items-center justify-center rounded-full sm:h-[32px] sm:w-[32px] sm:min-w-[32px]">
                <span className="duration-200 group-hover:mb-1 group-hover:ml-1">
                  <ArrowOutwardIcon color="#FBFBFC" width={8} height={8} />
                </span>
              </button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default TemplateCard;
