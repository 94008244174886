import clsx from "clsx";
import Link from "next/link";
import React, { ReactNode } from "react";

import css from "./TextLink.module.css";

interface TextLinkInterface {
  href: string;
  text: string;
  icon?: ReactNode;
  className?: string;
  target?: string;
  theme?: { readonly [key: string]: string };
}
const TextLink = ({ href = "#", text, icon, className, target, theme = {} }: TextLinkInterface) => {
  const linkTextStripped = text?.replace(/https?:\/\//g, "");
  return (
    <Link href={href} target={target} className={clsx(css.wrapper, theme.wrapper, className)}>
      <span className={clsx(css.text, theme.text)}>{linkTextStripped}</span>
      {icon && <span className={clsx(css.iconContainer, theme.iconContainer)}>{icon}</span>}
    </Link>
  );
};
export default TextLink;
