import React, { ReactElement } from "react";
import { graphql, useFragment } from "react-relay";

import CreateAppButton from "@components/shared/Buttons/CreateAppButton";
import PublishPackageButton from "@components/shared/Buttons/PublishPackageButton";
import { cn } from "@libs/helper";
//@ts-ignore
import staticIcon from "../icons/static.svg?url";
//@ts-ignore
import templateIcon from "../icons/template.svg?url";

import ActivityList from "./ActivityList";
import styles from "./index.module.css";
import Link from "next/link";
import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
import RegularButton from "@components/shared/Buttons/RegularButton";
import { DashboardActivityContext_data$key } from "@generated/DashboardActivityContext_data.graphql";
import { DashboardActivityViewer_data$key } from "@generated/DashboardActivityViewer_data.graphql";
import ShinyBox from "@components/ShinyBox";
import { EDGE_CLI_DOCS_URL } from "@utils/constants";

const DashboardActivity_dataFragment = graphql`
  fragment DashboardActivityViewer_data on User {
    registerIntent
    firstName
    username
  }
`;

const DashboardActivityContext_dataFragment = graphql`
  fragment DashboardActivityContext_data on GlobalObject {
    __typename
    ... on User {
      ...ActivityListForUser_data
      apps(sortBy: MOST_ACTIVE) {
        totalCount
      }
    }
    ... on Namespace {
      displayName
      ...ActivityListForNamespace_data
      apps(sortBy: MOST_ACTIVE) {
        totalCount
      }
    }
  }
`;

interface DashboardActivityProps {
  className?: string;
  viewerFragmentRef: DashboardActivityViewer_data$key;
  contextFragmentRef: DashboardActivityContext_data$key;
}

const PrimaryButton = ({ text, href }: { text: string; href: string }) => {
  return (
    <Link className="group block items-center justify-between rounded-xl bg-[#FBFBFC] p-4 px-6 sm:flex" href={href}>
      <span className="mr-4 text-left text-lg">{text}</span>
      <span className="bg-dark-black ml-auto mt-8 flex h-[36px] w-[36px] items-center justify-center rounded-full sm:mt-0">
        <span className="duration-200 group-hover:mb-1 group-hover:ml-1">
          <ArrowOutwardIcon color="white" width={12} height={12} />
        </span>
      </span>
    </Link>
  );
};

const IntentCard = ({
  icon,
  title,
  children,
  cta,
}: {
  icon: string;
  title: string;
  children: ReactElement;
  cta: ReactElement;
}) => {
  return (
    <ShinyBox blur="small" className="bg-dark-black bg-light-shadow relative rounded-lg">
      <div className=" p-6">
        <img src={icon} className="mx-auto mb-4 h-[88px] w-[88px]" />
        <h6 className="text-light-white mb-4 text-center text-new-lg font-medium">{title}</h6>
        <div className="mx-4 text-new-base	text-[#B4B1B8]">{children}</div>
        <div className="mx-4 mt-6 flex flex-col gap-4 md:mx-auto">{cta}</div>
      </div>
    </ShinyBox>
  );
};

const DashboardActivity: React.FC<DashboardActivityProps> = ({ className, viewerFragmentRef, contextFragmentRef }) => {
  const viewer = useFragment(DashboardActivity_dataFragment, viewerFragmentRef);
  const context = useFragment(DashboardActivityContext_dataFragment, contextFragmentRef);
  if (context?.__typename != "User" && context?.__typename != "Namespace") {
    return;
  }
  const showCards = viewer?.registerIntent === "static-site" && context.apps.totalCount == 0;

  return (
    <div className={cn(styles.wrapper, className)}>
      {showCards && (
        <div className="w-full">
          <h2 className={styles.title}>
            {context.__typename == "User"
              ? `Hello, ${viewer.firstName || viewer.username}!`
              : `${(context as any).displayName} Dashboard`}
          </h2>
          <div className="mt-4 flex  w-full flex-col items-center justify-center rounded-[12px] border border-mischka p-3 tablet:p-[32px]">
            {/* <p className="mb-6 text-new-lg">Welcome to Wasmer! Let’s get started.</p> */}
            <div className="z-0 grid grid-cols-1 gap-6 gap-8 lg:grid-cols-2 ">
              <IntentCard
                icon={staticIcon.src}
                title="Deploy your static app"
                cta={
                  <>
                    <PrimaryButton text="Deploy application" href="/apps/create" />
                    <RegularButton
                      className="w-full"
                      variant="secondary-on-black"
                      text="Deploy from CLI"
                      link={EDGE_CLI_DOCS_URL}
                    />
                  </>
                }
              >
                <ul className="list-disc">
                  <li>Connect your repository from Github</li>
                  <li>Deploy manually or automatically on new commits</li>
                  <li>Choose your preferred frameworks</li>
                </ul>
              </IntentCard>
              <IntentCard
                icon={templateIcon.src}
                title="Start from a template"
                cta={
                  <>
                    <PrimaryButton text="Browse templates" href="/templates" />
                    <RegularButton
                      className="w-full"
                      variant="secondary-on-black"
                      text="Start template from CLI"
                      link="https://docs.wasmer.io/"
                    />
                  </>
                }
              >
                <ul className="list-disc">
                  <li>Many frameworks available</li>
                  <li>Super easy to set up</li>
                  <li>Deployed automatically on new commits via CI</li>
                </ul>
              </IntentCard>
            </div>
          </div>
        </div>
      )}
      <div className={styles.header}>
        <h2 className={styles.title}>Activity</h2>
        {!showCards && (
          <div className={styles.buttonsWrapper}>
            <CreateAppButton />
            <PublishPackageButton />
          </div>
        )}
      </div>
      {context.__typename == "User" && <ActivityList fragmentRef={context} type={context.__typename} />}
      {context.__typename == "Namespace" && <ActivityList fragmentRef={context} type={context.__typename} />}
    </div>
  );
};

export default DashboardActivity;
