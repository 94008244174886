/**
 * @generated SignedSource<<2379205f6314564c5da7e79bbf6db392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardLeftSidebarPackageCard_data$data = {
  readonly icon: string;
  readonly id: string;
  readonly name: string;
  readonly owner: {
    readonly globalName: string;
  };
  readonly packageName: string;
  readonly " $fragmentType": "DashboardLeftSidebarPackageCard_data";
};
export type DashboardLeftSidebarPackageCard_data$key = {
  readonly " $data"?: DashboardLeftSidebarPackageCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebarPackageCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardLeftSidebarPackageCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "globalName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "packageName",
      "storageKey": null
    }
  ],
  "type": "Package",
  "abstractKey": null
};

(node as any).hash = "98465ef1aa8b149995de391f0c26c776";

export default node;
