/**
 * @generated SignedSource<<1c5bf7220e6c7428a801ce24b69cee76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardLeftSidebarAppCard_data$data = {
  readonly favicon: any | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly owner: {
    readonly globalName: string;
  };
  readonly " $fragmentType": "DashboardLeftSidebarAppCard_data";
};
export type DashboardLeftSidebarAppCard_data$key = {
  readonly " $data"?: DashboardLeftSidebarAppCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebarAppCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardLeftSidebarAppCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "globalName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favicon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "DeployApp",
  "abstractKey": null
};

(node as any).hash = "18a1eaf2e2a9b0ab267cf488ec247b61";

export default node;
