import React, { Fragment, useState } from "react";

import NewLoading from "@components/NewLoading";
import ContentCard from "@components/shared/Cards/ContentCard";
import { Listbox, Transition } from "@headlessui/react";

import OwnerNamespaceDropdownOption from "@components/PageCreateApp/AppOwnerDropdown/OwnerNamespaceDropdownOption";
import OwnerUserDropdownOption from "@components/PageCreateApp/AppOwnerDropdown/OwnerUserDropdownOption";
import styles from "./index.module.css";
import ArrowLeft from "@components/PageCreateApp/ArrowLeft";
import AddNamespaceModal from "../../ViewerNamespaces/AddNamespaceModal";
import Button from "@components/shared/Buttons/Button";
import FlatButton from "@components/shared/Buttons/RegularButton";
import SectionSeparator from "@components/SectionSeparator";

export enum OwnerType {
  user = "user",
  namespace = "namespace",
}

type CommonFields = {
  id: string;
  avatar: string;
  name: string;
};

export type NamespaceOwner = CommonFields & {
  type: OwnerType.namespace;
  globalName: string;
  description: string;
};

export type UserOwner = CommonFields & {
  type: OwnerType.user;
  username: string;
};

type OwnerList = (NamespaceOwner | UserOwner)[];

interface AppOwnerDropdownProps {
  ownerList: OwnerList;
  children: React.ReactElement;
  handleOwnerChange: (newId: string) => void;
  onLoadingVisible?: () => void;
  hasMore?: boolean;
}

const AppOwnerDropdown: React.FC<AppOwnerDropdownProps> = ({
  ownerList,
  handleOwnerChange,
  hasMore,
  onLoadingVisible,
  children,
}) => {
  const [nspModal, setNspModal] = useState(false);

  const handleCloseAddNamespaceModal = () => {
    setNspModal(false);
  };

  const handleAddNamespaceModal = () => {
    setNspModal(true);
  };

  return (
    <>
      <AddNamespaceModal isOpen={nspModal} handleClose={handleCloseAddNamespaceModal} />
      <Listbox as="div" onChange={handleOwnerChange} className={styles.menu}>
        <div>
          <Listbox.Button className={styles.menuButton} data-cy={"pkg-dropdown-versions"}>
            {children}
          </Listbox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Listbox.Options className={styles.menuItems}>
            <ContentCard className={styles.contentCard}>
              {ownerList?.map((owner) => {
                if (owner.type === OwnerType.user) {
                  return (
                    <Listbox.Option
                      key={owner.id}
                      value={owner.id}
                      className="w-full cursor-pointer rounded-[8px] p-[12px] ui-active:bg-[#0F0518]/[10%]"
                    >
                      <OwnerUserDropdownOption name={owner.name} username={owner.username} avatar={owner.avatar} />
                    </Listbox.Option>
                  );
                } else if (owner.type === OwnerType.namespace) {
                  return (
                    <Listbox.Option
                      key={owner.id}
                      value={owner.id}
                      className="w-full cursor-pointer rounded-[8px] p-[12px] ui-active:bg-[#0F0518]/[10%]"
                    >
                      <OwnerNamespaceDropdownOption
                        name={owner.name}
                        globalName={owner.globalName}
                        avatar={owner.avatar}
                      />
                    </Listbox.Option>
                  );
                }
              })}
              {!hasMore ? null : (
                <Listbox.Option
                  key="loading"
                  value="loading"
                  className="flex w-full cursor-pointer items-center justify-center rounded-[8px] p-[12px] ui-active:bg-[#0F0518]/[10%]"
                >
                  <NewLoading className="w-[24px]" onVisible={onLoadingVisible} />
                </Listbox.Option>
              )}
              <SectionSeparator className="mb-4 mt-2" />
              <FlatButton
                className="w-full"
                variant="tertiary"
                text="Create namespace"
                onClick={handleAddNamespaceModal}
              ></FlatButton>
            </ContentCard>
          </Listbox.Options>
        </Transition>
      </Listbox>
    </>
  );
};

export default AppOwnerDropdown;
