/**
 * @generated SignedSource<<b60667afb098d5239d9840315e4cf6ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardLeftSidebar_data$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"ViewerCard_data">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ReadBlogSection_data">;
  readonly " $fragmentType": "DashboardLeftSidebar_data";
};
export type DashboardLeftSidebar_data$key = {
  readonly " $data"?: DashboardLeftSidebar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebar_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardLeftSidebar_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ViewerCard_data"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReadBlogSection_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9e051e314b116ae09112261ae66e5828";

export default node;
