/**
 * @generated SignedSource<<ec32b038a4215f3cdb90d168a9ebc5de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardActivityViewer_data$data = {
  readonly firstName: string;
  readonly registerIntent: string | null | undefined;
  readonly username: string;
  readonly " $fragmentType": "DashboardActivityViewer_data";
};
export type DashboardActivityViewer_data$key = {
  readonly " $data"?: DashboardActivityViewer_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityViewer_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardActivityViewer_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registerIntent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "a1bfb3b005682bf5c2863e6810d32e1e";

export default node;
