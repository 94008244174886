import Image, { StaticImageData } from "next/image";
import React from "react";

type Props = {
  name: string;
  icon: StaticImageData;
  title?: string;
  selected: boolean;
  onClick?: () => void;
};

const PackageCard = ({ icon, title, selected, onClick }: Props) => {
  return (
    <div
      className={` bg-light-gradient bg-light-shadow m-4 inline-flex ${
        onClick ? "cursor-pointer" : ""
      } p-new-1 items-center rounded-xl border border-[#0f051833]`}
      style={{
        outline: `${selected ? "1px solid rgba(15, 5, 24, 0.2)" : "1px solid rgba(15, 5, 24, 0)"}`,
      }}
      onClick={onClick}
    >
      <Image src={icon} alt={title || ""} width={56} height={56} className=" p-1" />
      {title && (
        <p className={`ml-4 whitespace-nowrap pr-4 text-[15px] font-medium ${selected ? "" : "text-dark-gray"}`}>
          {title}
        </p>
      )}
    </div>
  );
};

export default PackageCard;
