/**
 * @generated SignedSource<<e2aa3dad99334dd1e6718b0e8d3ca4fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReadBlogSection_data$data = {
  readonly blogposts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly title: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly " $fragmentType": "ReadBlogSection_data";
};
export type ReadBlogSection_data$key = {
  readonly " $data"?: ReadBlogSection_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReadBlogSection_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReadBlogSection_data",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "BlogPostConnection",
      "kind": "LinkedField",
      "name": "blogposts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BlogPostEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BlogPost",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "blogposts(first:1)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "21168f2c8b105154ab20368e8c0226ba";

export default node;
