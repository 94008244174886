/**
 * @generated SignedSource<<612ac0f06c0f723a1b5034bc7ecd7f50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Dashboard_data$data = {
  readonly context: {
    readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityContext_data" | "DashboardLeftSidebarContext_data">;
  } | null | undefined;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DashboardActivityViewer_data">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardLeftSidebar_data">;
  readonly " $fragmentType": "Dashboard_data";
};
export type Dashboard_data$key = {
  readonly " $data"?: Dashboard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"Dashboard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "context"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Dashboard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DashboardActivityViewer_data"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardLeftSidebar_data"
    },
    {
      "alias": "context",
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "context"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "getGlobalObject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DashboardActivityContext_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DashboardLeftSidebarContext_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f060e46d8f86cdd54b0b82470cd4ca4e";

export default node;
