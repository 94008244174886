import Link from "next/link";
import React from "react";
import { graphql, useFragment } from "react-relay";

import ArrowLeft from "@components/PageCreateApp/ArrowLeft";
import { ReadBlogSection_data$key } from "@generated/ReadBlogSection_data.graphql";

const ReadBlogSection_dataFragment = graphql`
  fragment ReadBlogSection_data on Query {
    blogposts(first: 1) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

interface ReadBlogSectionProps {
  fragmentRef: ReadBlogSection_data$key;
}

const ReadBlogSection: React.FC<ReadBlogSectionProps> = ({ fragmentRef }) => {
  const data = useFragment(ReadBlogSection_dataFragment, fragmentRef);

  const firstBlogPost = data.blogposts?.edges?.[0]?.node;

  return (
    <div
      className="flex w-full flex-col items-start justify-start gap-4 border-t border-mischka px-[40px] py-[24px]"
      data-cy="read-blog-section"
    >
      <h5 className="font-gilroy text-[16px] font-[600] leading-[140%] text-[#0F0518]">Read our blog</h5>
      <div className="flex w-full items-center justify-between gap-1">
        <Link href={`/posts/${firstBlogPost?.slug}`}>
          <p className="font-gilroy text-[16px] font-[600] leading-[140%] text-[#0F0518]/50 hover:underline">
            {firstBlogPost?.title}
          </p>
        </Link>
        <ArrowLeft className="w-[6px] min-w-[6px] rotate-180 opacity-50" />
      </div>
    </div>
  );
};

export default ReadBlogSection;
