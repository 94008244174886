/**
 * @generated SignedSource<<55930747dce9ba96c7522c1df3e5086f>>
 * @relayHash 40655a5b9fda67c826b2d0365b47987b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 40655a5b9fda67c826b2d0365b47987b

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PageHomeQuery$variables = {
  context: string;
};
export type PageHomeQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"LayoutWrapper_data">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Dashboard_data" | "PublicHomePage_data">;
};
export type PageHomeQuery = {
  response: PageHomeQuery$data;
  variables: PageHomeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "context"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 4
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isNode"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "likersCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadsCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "globalName",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v1/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watchersCount",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasLiked",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsWatching",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "packageName",
  "storageKey": null
},
v20 = {
  "kind": "InlineFragment",
  "selections": [
    (v11/*: any*/)
  ],
  "type": "Namespace",
  "abstractKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offset",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "length",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v25 = [
  (v11/*: any*/),
  (v3/*: any*/),
  (v1/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "declinedBy",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "approvedBy",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "requestedBy",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v30 = [
  (v1/*: any*/),
  (v24/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Package",
    "kind": "LinkedField",
    "name": "package",
    "plural": false,
    "selections": [
      (v13/*: any*/),
      (v19/*: any*/),
      (v18/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  }
],
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v36 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v37 = [
  (v36/*: any*/)
],
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v39 = {
  "alias": "typeResult",
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showDeployButton",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "concreteType": "PackageCollaboratorConnection",
  "kind": "LinkedField",
  "name": "collaborators",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageCollaboratorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PackageCollaborator",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v2/*: any*/),
                (v31/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v43 = [
  (v39/*: any*/),
  (v38/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Package",
    "kind": "LinkedField",
    "name": "package",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v9/*: any*/),
      (v15/*: any*/),
      (v40/*: any*/),
      (v13/*: any*/),
      (v42/*: any*/)
    ],
    "storageKey": null
  },
  (v1/*: any*/)
],
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v45 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 6
  }
],
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favicon",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "concreteType": "Package",
  "kind": "LinkedField",
  "name": "sourcePackage",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v18/*: any*/),
    (v8/*: any*/),
    (v19/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "DeployApp",
  "kind": "LinkedField",
  "name": "app",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v13/*: any*/),
    (v1/*: any*/),
    (v48/*: any*/)
  ],
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "publishedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v1/*: any*/),
    (v34/*: any*/),
    (v35/*: any*/),
    (v3/*: any*/),
    (v31/*: any*/)
  ],
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientName",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "screenshot",
  "storageKey": null
},
v54 = [
  (v6/*: any*/),
  (v1/*: any*/)
],
v55 = {
  "alias": "activity",
  "args": (v45/*: any*/),
  "concreteType": "ActivityEventConnection",
  "kind": "LinkedField",
  "name": "publicActivity",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivityEventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityEvent",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "EventBody",
              "kind": "LinkedField",
              "name": "body",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NodeBodyRange",
                  "kind": "LinkedField",
                  "name": "ranges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": [
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "type": "User",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v8/*: any*/),
                            (v9/*: any*/),
                            (v10/*: any*/),
                            (v13/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v16/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PackageVersion",
                              "kind": "LinkedField",
                              "name": "lastVersion",
                              "plural": false,
                              "selections": (v43/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "Package",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v17/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Package",
                              "kind": "LinkedField",
                              "name": "package",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                (v18/*: any*/),
                                (v8/*: any*/),
                                (v19/*: any*/),
                                (v13/*: any*/),
                                (v9/*: any*/),
                                (v10/*: any*/),
                                (v14/*: any*/),
                                (v15/*: any*/),
                                (v16/*: any*/),
                                (v40/*: any*/),
                                (v42/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v39/*: any*/),
                            (v38/*: any*/)
                          ],
                          "type": "PackageVersion",
                          "abstractKey": null
                        },
                        (v20/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v21/*: any*/),
                            (v44/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BlogPostTag",
                              "kind": "LinkedField",
                              "name": "tags",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "coverImageUrl",
                              "storageKey": null
                            }
                          ],
                          "type": "BlogPost",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v8/*: any*/),
                            (v13/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "DeployAppVersion",
                              "kind": "LinkedField",
                              "name": "activeVersion",
                              "plural": false,
                              "selections": [
                                (v46/*: any*/),
                                (v47/*: any*/),
                                (v49/*: any*/),
                                (v50/*: any*/),
                                (v48/*: any*/),
                                (v51/*: any*/),
                                (v52/*: any*/),
                                (v53/*: any*/),
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "DeployApp",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v46/*: any*/),
                            (v47/*: any*/),
                            (v49/*: any*/),
                            (v50/*: any*/),
                            (v48/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/),
                            (v53/*: any*/)
                          ],
                          "type": "DeployAppVersion",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v22/*: any*/),
                    (v23/*: any*/),
                    {
                      "alias": "packageEntity",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v54/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "packageVersionEntity",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v54/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "blogPostEntity",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v54/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "appEntity",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v54/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": "appVersionEntity",
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "entity",
                      "plural": false,
                      "selections": (v54/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actorIcon",
              "storageKey": null
            },
            (v24/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        (v32/*: any*/)
      ],
      "storageKey": null
    },
    (v33/*: any*/)
  ],
  "storageKey": "publicActivity(first:6)"
},
v56 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": "MOST_ACTIVE"
},
v57 = {
  "alias": null,
  "args": [
    (v56/*: any*/)
  ],
  "concreteType": "DeployAppConnection",
  "kind": "LinkedField",
  "name": "apps",
  "plural": false,
  "selections": [
    (v41/*: any*/)
  ],
  "storageKey": "apps(sortBy:\"MOST_ACTIVE\")"
},
v58 = {
  "kind": "Literal",
  "name": "first",
  "value": 5
},
v59 = {
  "alias": "popularApps",
  "args": [
    (v58/*: any*/),
    (v56/*: any*/)
  ],
  "concreteType": "DeployAppConnection",
  "kind": "LinkedField",
  "name": "apps",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DeployAppEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeployApp",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v13/*: any*/),
            (v46/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "apps(first:5,sortBy:\"MOST_ACTIVE\")"
},
v60 = {
  "alias": "popularPackages",
  "args": [
    (v58/*: any*/)
  ],
  "concreteType": "PackageConnection",
  "kind": "LinkedField",
  "name": "packages",
  "plural": false,
  "selections": [
    (v41/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PackageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Package",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v13/*: any*/),
            (v18/*: any*/),
            (v8/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "packages(first:5)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LayoutWrapper_data"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PublicHomePage_data"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "context",
            "variableName": "context"
          }
        ],
        "kind": "FragmentSpread",
        "name": "Dashboard_data"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "UserNotificationConnection",
            "kind": "LinkedField",
            "name": "notifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPendingNotifications",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNotificationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserNotification",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EventBody",
                        "kind": "LinkedField",
                        "name": "body",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "NodeBodyRange",
                            "kind": "LinkedField",
                            "name": "ranges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "entity",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "type": "User",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/),
                                      (v15/*: any*/),
                                      (v16/*: any*/)
                                    ],
                                    "type": "Package",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v17/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Package",
                                        "kind": "LinkedField",
                                        "name": "package",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          (v18/*: any*/),
                                          (v8/*: any*/),
                                          (v19/*: any*/),
                                          (v13/*: any*/),
                                          (v9/*: any*/),
                                          (v10/*: any*/),
                                          (v14/*: any*/),
                                          (v15/*: any*/),
                                          (v16/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "PackageVersion",
                                    "abstractKey": null
                                  },
                                  (v20/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v21/*: any*/)
                                    ],
                                    "type": "BlogPost",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v8/*: any*/),
                                      (v13/*: any*/)
                                    ],
                                    "type": "DeployApp",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v22/*: any*/),
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "seenState",
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "kind",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "NamespaceCollaboratorInvite",
                                "kind": "LinkedField",
                                "name": "namespaceInvite",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v24/*: any*/),
                                  (v26/*: any*/),
                                  (v27/*: any*/),
                                  (v28/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Namespace",
                                    "kind": "LinkedField",
                                    "name": "namespace",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "globalId",
                                        "storageKey": null
                                      },
                                      (v11/*: any*/),
                                      (v29/*: any*/),
                                      (v3/*: any*/),
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "UserNotificationKindIncomingNamespaceInvite",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PackageCollaboratorInvite",
                                "kind": "LinkedField",
                                "name": "packageInvite",
                                "plural": false,
                                "selections": (v30/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "UserNotificationKindIncomingPackageInvite",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PackageTransferRequest",
                                "kind": "LinkedField",
                                "name": "packageTransferRequest",
                                "plural": false,
                                "selections": (v30/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "UserNotificationKindIncomingPackageTransfer",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v31/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isEmailValidated",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "UserNotificationKindValidateEmail",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v32/*: any*/)
                ],
                "storageKey": null
              },
              (v33/*: any*/)
            ],
            "storageKey": "notifications(first:4)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "NotificationMenu_notifications",
            "kind": "LinkedHandle",
            "name": "notifications"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registerIntent",
            "storageKey": null
          },
          (v34/*: any*/),
          (v35/*: any*/),
          {
            "alias": null,
            "args": (v37/*: any*/),
            "concreteType": "NamespaceConnection",
            "kind": "LinkedField",
            "name": "namespaces",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NamespaceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Namespace",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v29/*: any*/),
                      (v11/*: any*/),
                      (v38/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v32/*: any*/)
                ],
                "storageKey": null
              },
              (v33/*: any*/)
            ],
            "storageKey": "namespaces(first:10)"
          },
          {
            "alias": null,
            "args": (v37/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "OwnerSection_namespaces",
            "kind": "LinkedHandle",
            "name": "namespaces"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "curated",
            "value": true
          },
          (v36/*: any*/)
        ],
        "concreteType": "PackageVersionConnection",
        "kind": "LinkedField",
        "name": "recentPackageVersions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PackageVersionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageVersion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v43/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "recentPackageVersions(curated:true,first:10)"
      },
      {
        "alias": "apps",
        "args": [
          {
            "kind": "Literal",
            "name": "categorySlug",
            "value": "popular"
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 3
          }
        ],
        "concreteType": "AppTemplateConnection",
        "kind": "LinkedField",
        "name": "getAppTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AppTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v8/*: any*/),
                  (v38/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "demoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "repoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "framework",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "defaultImage",
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "getAppTemplates(categorySlug:\"popular\",first:3)"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1
          }
        ],
        "concreteType": "BlogPostConnection",
        "kind": "LinkedField",
        "name": "blogposts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BlogPostEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BlogPost",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v44/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "blogposts(first:1)"
      },
      {
        "alias": "context",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "context"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "getGlobalObject",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isGlobalObject"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v55/*: any*/),
              {
                "alias": "activity",
                "args": (v45/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ActivityList_activity",
                "kind": "LinkedHandle",
                "name": "publicActivity"
              },
              (v1/*: any*/),
              (v57/*: any*/),
              (v2/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v3/*: any*/),
              (v59/*: any*/),
              (v60/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v29/*: any*/),
              (v55/*: any*/),
              {
                "alias": "activity",
                "args": (v45/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ActivityListForNamespace_activity",
                "kind": "LinkedHandle",
                "name": "publicActivity"
              },
              (v1/*: any*/),
              (v57/*: any*/),
              (v11/*: any*/),
              (v3/*: any*/),
              (v59/*: any*/),
              (v60/*: any*/)
            ],
            "type": "Namespace",
            "abstractKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "40655a5b9fda67c826b2d0365b47987b",
    "metadata": {},
    "name": "PageHomeQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4b5ce34355ae3f458af815b0be9c2dd7";

export default node;
