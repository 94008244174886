import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import React, { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import ArrowOutwardIcon from "@assets/icons/ArrowOutwardIcon";
import DiscordLogo from "@assets/icons/DiscordLogo";
// @ts-ignore
import deploy from "@assets/icons/deploy.svg?.url";
// @ts-ignore
import kongIcon from "@assets/icons/kongIcon.svg?url";
// @ts-ignore
import macosIcon from "@assets/icons/macosIcon.svg?url";
// @ts-ignore
import metaIcon from "@assets/icons/metaIcon.svg?url";
// @ts-ignore
// @ts-ignore
import publish from "@assets/icons/publish.svg?url";
// @ts-ignore
import run from "@assets/icons/run.svg?url";
// @ts-ignore
import sandbox from "@assets/icons/sandbox.svg?url";
// @ts-ignore
import star from "@assets/icons/star.svg?url";
import EdgeCode from "@components/home/EdgeCode";
import RegistryCode from "@components/home/RegistryCode";
import RuntimeCode from "@components/home/RuntimeCode";
import TerminalCard from "@components/home/TerminalCard";
import IconButton from "@components/shared/Buttons/IconButton";
import ProductButton from "@components/shared/Buttons/ProductButton";
import { PublicHomePage_data$key } from "@generated/PublicHomePage_data.graphql";
import { cn, scrollToTitleId } from "@libs/helper";
import { DISCORD_INVITE } from "@utils/constants";

import css from "./PublicHomePage.module.css";

// The default number of stars to show
const DEFAULT_NUM_STARS = 15000;
const numToStarsString = (num: number) => {
  const numStars = num.toLocaleString("en-US");
  // numStars.replace(",", ".");
  return `${numStars}`;
};

const PublicHomePage_dataFragment = graphql`
  fragment PublicHomePage_data on Query {
    ...RegistryCode_recentPackages
    ...EdgeCode_Query
  }
`;

interface PublicHomePageProps {
  fragmentRef: PublicHomePage_data$key;
  className?: string;
  os?: string
}
const PublicHomePage: React.FC<PublicHomePageProps> = ({
  fragmentRef,
  className,
  os
}) => {
  const data = useFragment(PublicHomePage_dataFragment, fragmentRef);
  const [stars, setNumStars] = React.useState(
    numToStarsString(DEFAULT_NUM_STARS)
  );

  const getCounter = useCallback(async () => {
    if (typeof window === "undefined") return DEFAULT_NUM_STARS;
    const resource = await fetch(
      "https://api.github.com/repos/wasmerio/wasmer"
    );
    const data = await resource.json();
    return (data as any).stargazers_count;
  }, []);
  React.useEffect(() => {
    getCounter().then((numStars) => setNumStars(numToStarsString(numStars)));
  }, [getCounter]);
  return (
    <div
      className={cn("flex flex-col gap-[64px] pt-16 md:pt-[88px]", className)}
    >
      <Head>
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_WASMER_URL}/images/banner.jpeg`}
        />
        <meta
          property="og:image:alt"
          content="Wasmer - Run, Publish & Deploy any code - anywhere"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content={`${process.env.NEXT_PUBLIC_WASMER_URL}/images/banner.jpeg`}
        />
      </Head>

      <h1 className={css.mainHeader}>Truly Universal Apps with WebAssembly</h1>
      <div className="flex flex-col justify-center overflow-hidden sm:overflow-visible">
        <TerminalCard os={os} />
        <div className="mt-[24px] overflow-hidden text-center">
          <h3 className="mb-4 px-4 text-new-xl font-medium lg:text-[22px]">
            Get involved in the awesome community!
          </h3>
          <div className=" flex flex-row items-center justify-center  gap-[8px]   ">
            <Link
              href="https://github.com/wasmerio/wasmer"
              target="_blank"
              className="flex w-fit items-center justify-center rounded-full border border-[#0e061a26] px-5 py-2.5 hover:bg-[#0f05180d]"
            >
              <span className="mr-4 flex items-center border-r border-[#0f051833] pr-4 text-[22px] font-medium leading-[31px]">
                {stars} <Image src={star} alt="star" className="ml-2" />
              </span>
              <div className="flex items-center">
                <Image src={sandbox} alt="sandbox" className="mr-2" />
              </div>
            </Link>
            <Link href={DISCORD_INVITE} target="_blank">
              <IconButton
                icon={
                  <div className="flex flex-row items-center justify-center gap-[8px]">
                    <DiscordLogo
                      fill="#0F0518"
                      className="h-[24px] w-[24px] "
                    />
                    <ArrowOutwardIcon color="#0F0518" width={12} height={12} />
                  </div>
                }
                className="flex h-[53px] w-fit items-center justify-center rounded-full border border-[#0e061a26] px-5 py-2.5 hover:bg-[#0f05180d]"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center pt-[24px]">
        <div className="flex items-center justify-center gap-[32px]">
          <div className="font-gilroy text-[16px] font-normal not-italic leading-p140 text-[#0F0518] opacity-50">
            Trusted by devs at
          </div>
          <div className="flex flex-row gap-[36px] opacity-50  ">
            <Image src={metaIcon} alt="metaIcon" width={110} height={22} />
            <Image src={macosIcon} alt="MacOs" width={24} height={28} />
            <Image src={kongIcon} alt="Kong" width={100} height={32} />
          </div>
        </div>
      </div>
      <h2 className="mx-[30px] mb-[50px] mt-[75px] max-w-[1050px] text-center text-[38px] font-medium leading-[67px] sm:mx-4 sm:mt-[144px] md:text-[34px] lg:mx-auto lg:text-[48px] lg:leading-[77px]">
        Create apps that{" "}
        <ProductButton
          handleClick={() => scrollToTitleId("runtime-home-section")}
          icon={run}
          title="Run"
          arrow={true}
          target="runtime"
        />{" "}
        everywhere.
        <ProductButton
          handleClick={() => scrollToTitleId("registry-home-section")}
          icon={publish}
          title="Publish"
          arrow={true}
          target="publish"
        />
        , share with the community and
        <ProductButton
          handleClick={() => scrollToTitleId("edge-home-section")}
          icon={deploy}
          title="Deploy"
          arrow={true}
          target="products/edge"
        />{" "}
        to the edge, globally.
      </h2>
      <RuntimeCode id="runtime" />
      <RegistryCode id="publish" fragmentRef={data} />
      <EdgeCode id="deploy" fragmentRef={data} />
    </div>
  );
};

export default PublicHomePage;
