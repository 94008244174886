import React from "react";

import UserAvatarIcon from "@components/UserAvatarIcon";

import styles from "./index.module.css";

interface OwnerUserDropdownOptionProps {
  name: string;
  username: string;
  avatar: string;
}
const OwnerUserDropdownOption: React.FC<OwnerUserDropdownOptionProps> = ({ name, username, avatar }) => {
  return (
    <div className="flex w-full items-center justify-start gap-3">
      <UserAvatarIcon src={avatar} username={username} className={styles.OwnerUserDropdownOptionUserAvatarIcon} />
      <div className="flex w-full flex-col items-start justify-start">
        <p className="font-gilroy text-[16px] font-[600] leading-[130%] text-[#0F0518]">{name}</p>
        <p className="whitespace-nowrap font-gilroy text-[13px] font-[400] leading-[130%] text-[#0F0518]/50">
          {username}
        </p>
      </div>
    </div>
  );
};

export default OwnerUserDropdownOption;
